// Copyright 2024 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { Button, Checkbox, Heading, Select, useTheme } from "@merit/frontend-components";
import {
  CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum as ColumnDataTypeEnum,
  CreateDatasourceRequestIntegrationTypeEnum,
  CreateDatasourceRequestSchemaDelimiterEnum,
} from "@src/gen/org-portal";
import { ConfirmationModal } from "@src/components/Modals";
import { EllipsisText, HorizontalSpacer, VerticalSpacer } from "../../../components";
import { ErrorMessage, Formik } from "formik";
import { GetDatasourceColumnTypes } from "../../Datasources/DatasourceColumnTypes";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import { useAlertStore, useAppConstantsStore } from "@src/stores";
import { useCallback, useMemo, useRef, useState } from "react";
import { usePreviewDataSourceStyles } from "./styles";
import { v4 as uuidv4 } from "uuid";
import type { Column } from "../types";
import type { FormikProps } from "formik";

export type FormValues = {
  readonly name: string;
  readonly description: string;
  readonly schemaColumns: readonly Column[];
  readonly integrationType: CreateDatasourceRequestIntegrationTypeEnum;
  readonly schemaDelimiter: CreateDatasourceRequestSchemaDelimiterEnum;
};

type Props = {
  readonly columns: readonly Column[];
  readonly fileName: string;
  readonly onNext: (dataSource: FormValues) => void;
  readonly onBack: () => void;
  readonly templateType: string;
};

const dataTypesToDisableUniqueIdentifier = [
  ColumnDataTypeEnum.Blob,
  ColumnDataTypeEnum.Json,
] as readonly string[];

const { None } = Helpers;

export const SCREEN_NAME = "PreviewDataSource";

export const PreviewDataSource = ({ columns, fileName, onBack, onNext, templateType }: Props) => {
  const { theme } = useTheme();
  const { setAlert } = useAlertStore();
  const styles = usePreviewDataSourceStyles();
  const { folioFieldNames, meritFieldNames } = useAppConstantsStore();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const datasourceColumnTypes = GetDatasourceColumnTypes(false);

  const meritFields = useMemo(
    () => Object.values(meritFieldNames).map(_ => _.toLowerCase()),
    [meritFieldNames]
  );

  const folioFields = useMemo(
    () => Object.values(folioFieldNames).map(_ => _.toLowerCase()),
    [folioFieldNames]
  );

  const showAlert = (errMessage: string) => {
    setAlert({
      id: uuidv4(),
      onPressDelete: () => undefined,
      text: errMessage,
      title: "Failed",
      type: "error",
    });
  };

  const errorText = (errorMessage: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      <Heading color={theme.colors.text.alert.critical} level="6">
        {errorMessage}
      </Heading>
    </View>
  );

  const computeDataFormatSelectProps = (column: Column) => {
    switch (column.columnDataType) {
      case "PhoneNumber":
        return {
          defaultValue: { label: "US", value: "US" },
          options: [{ label: "US", value: "US" }],
        };

      case "Date":
        return {
          defaultValue: { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
          options: [
            { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
            { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
          ],
        };

      case "DateTime":
        return {
          defaultValue: { label: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
          options: [
            { label: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
            { label: "MM-DD-YYYY HH:mm:ss", value: "MM-DD-YYYY HH:mm:ss" },
          ],
        };

      default:
        return {
          defaultValue: { label: "Format", value: "" },
          options: [],
        };
    }
  };

  const initialFormValues = useMemo(
    () => ({
      description: "",
      integrationType: CreateDatasourceRequestIntegrationTypeEnum.Csv,
      name: fileName,
      schemaColumns: columns,
      schemaDelimiter: CreateDatasourceRequestSchemaDelimiterEnum.Comma,
    }),
    [columns, fileName]
  );

  const validationSchema = () => {
    const name = yup.string().required().max(60).label("Data Source Name");
    const description = yup.string().max(300).label("Data Source Description");
    const schemaColumns = yup
      .array()
      .of(
        yup.object({
          columnDataType: yup.string().label("Column Data Type").required(),
          columnName: yup.string().label("Column Name").required(),
        })
      )
      .label("Datasource schema");

    return yup.object({
      description,
      name,
      schemaColumns: schemaColumns.min(1),
    });
  };

  const handleNext = (payload: FormValues) => {
    if (
      payload.schemaColumns.filter(column => column.isIdentifier && column.selected).length === 0
    ) {
      showAlert("At least one unique identifier must be checked");

      return;
    }

    const checkValues = payload.schemaColumns.filter(
      column => ["Date", "DateTime"].includes(column.columnDataType) && column.format === undefined
    );

    if (checkValues.length > 0) {
      showAlert("Column Format is a required field");

      return;
    }

    onNext(payload);
  };

  const onSelectDataType = useCallback((dataType: string, fieldIndex: number) => {
    if (None(formRef.current)) {
      return;
    }

    const { setFieldValue } = formRef.current;

    setFieldValue(`schemaColumns.${fieldIndex}.columnDataType`, dataType);

    if (dataTypesToDisableUniqueIdentifier.includes(dataType)) {
      setFieldValue(`schemaColumns.${fieldIndex}.isIdentifier`, false);
    }

    if (dataType === "PhoneNumber") {
      setFieldValue(`schemaColumns.${fieldIndex}.format`, "US");
    }

    if (!["Date", "DateTime"].includes(dataType)) {
      setFieldValue(`schemaColumns.${fieldIndex}.format`, undefined);
    }

    if (dataType === "Date") {
      setFieldValue(`schemaColumns.${fieldIndex}.format`, "YYYY-MM-DD");
    }

    if (dataType === "DateTime") {
      setFieldValue(`schemaColumns.${fieldIndex}.format`, "YYYY-MM-DD HH:mm:ss");
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        innerRef={formRef}
        onSubmit={handleNext}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <View style={styles.container}>
            <ScrollView>
              <View style={styles.content}>
                <>
                  <Heading
                    bold
                    level="3"
                    testProps={{
                      elementName: "datasourceSchemaHeader",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    Data source Schema
                  </Heading>
                  <VerticalSpacer size={theme.spacing.xxl} />
                  <View style={styles.schemaContainer}>
                    <View style={styles.schemaTableRow}>
                      <View style={styles.checkboxAll}>
                        <Heading level="5">
                          <Checkbox
                            defaultChecked={
                              values.schemaColumns.length > 0 &&
                              values.schemaColumns.length ===
                                values.schemaColumns.filter(column => column.selected).length
                            }
                            onChange={value => {
                              const fieldNames =
                                templateType === "Merit" ? meritFields : folioFields;
                              setFieldValue(
                                "schemaColumns",
                                values.schemaColumns.map(column => ({
                                  ...column,
                                  selected: value
                                    ? value
                                    : fieldNames.includes(column.columnName.toLowerCase()),
                                }))
                              );
                            }}
                          />
                        </Heading>
                      </View>
                      <View style={styles.schemaColumnName}>
                        <Heading level="5">Column name</Heading>
                      </View>
                      <View style={styles.schemaColumnDatatype}>
                        <Heading level="5">Data type</Heading>
                      </View>
                      <View style={styles.schemaColumnFormat}>
                        <Heading level="5">Format</Heading>
                      </View>
                      <View style={styles.schemaColumnIdentifier}>
                        <Heading level="5">Unique identifier</Heading>
                      </View>
                    </View>

                    {values.schemaColumns.map((column, index) => (
                      <View key={column.id} style={styles.schemaTableRow}>
                        <View style={styles.checkboxAll}>
                          <Checkbox
                            defaultChecked={column.selected}
                            onChange={value => {
                              setFieldValue(`schemaColumns.${index}.selected`, value);
                            }}
                          />
                        </View>
                        <View style={styles.schemaColumnName}>
                          <View style={[styles.fakeField, { paddingTop: 10 }]}>
                            <EllipsisText text={column.columnName} />
                          </View>
                        </View>

                        <View style={styles.schemaColumnDatatype}>
                          <Select
                            defaultValue={
                              datasourceColumnTypes.find(
                                item => item.value === column.columnDataType
                              ) ?? {
                                label: "Data type",
                                value: "",
                              }
                            }
                            label="Data type"
                            onSelectOption={item => {
                              if (typeof item.value === "string") {
                                onSelectDataType(item.value, index);
                              }
                            }}
                            options={datasourceColumnTypes}
                            showLabel={false}
                            usePortal
                          />
                        </View>

                        <View style={styles.schemaColumnFormat}>
                          <Select
                            disabled={!["Date", "DateTime"].includes(column.columnDataType)}
                            key={column.columnDataType}
                            label="Format"
                            onSelectOption={item => {
                              setFieldValue(`schemaColumns.${index}.format`, item.value);
                            }}
                            showLabel={false}
                            usePortal
                            {...computeDataFormatSelectProps(column)}
                          />
                          <ErrorMessage name={`schemaColumns.${index}.format`}>
                            {error => errorText(error)}
                          </ErrorMessage>
                        </View>

                        <View style={styles.schemaColumnIdentifier}>
                          <Checkbox
                            defaultChecked={column.isIdentifier}
                            disabled={dataTypesToDisableUniqueIdentifier.includes(
                              column.columnDataType
                            )}
                            onChange={value => {
                              setFieldValue(`schemaColumns.${index}.isIdentifier`, value);
                            }}
                            testProps={{
                              elementId: column.columnName,
                              elementName: "Column",
                              screenName: SCREEN_NAME,
                            }}
                          />
                        </View>
                      </View>
                    ))}
                  </View>

                  <VerticalSpacer size={theme.spacing.xxl} />
                </>
              </View>
            </ScrollView>

            <View style={styles.footer}>
              <Button
                onPress={() => {
                  setIsConfirmationModalOpen(true);
                }}
                testProps={{
                  elementName: "cancelButton",
                  screenName: SCREEN_NAME,
                }}
                text="Cancel"
                type="secondary"
              />
              <HorizontalSpacer />
              <Button
                onPress={handleSubmit}
                testProps={{
                  elementName: "nextButton",
                  screenName: SCREEN_NAME,
                }}
                text="Next"
              />
            </View>
          </View>
        )}
      </Formik>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="ok"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            setIsConfirmationModalOpen(false);
            onBack();
          }}
          testProps={{
            elementName: "okButton",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and submit the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
