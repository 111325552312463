// Copyright 2024 Merit International Inc. All Rights Reserved.

import { Helpers } from "@merit/frontend-utils";
import { useApi } from "../api/api";
import { useAppConstantsStore } from "../stores";
import { useEffect, useState } from "react";
import { useLoadedConfigurationState } from "./useLoadedConfigurationState";
import { useLoggedInAuthState } from "./loggedInAuthState";
import { useOrgLogoStore } from "../stores/orgLogoStore";
import { useTheme } from "@merit/frontend-components";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "../configuration";

const envConfig = Constants.manifest?.extra as EnvConfig;
const { Some } = Helpers;

type OrgLogo = {
  readonly orgLogo: string;
  readonly refreshOrgLogo: () => void;
};

export const useOrgLogo = (): OrgLogo => {
  const { api } = useApi();
  const { theme } = useTheme();
  const { accountFolioFieldNames } = useAppConstantsStore();
  const { selectedOrgId } = useLoggedInAuthState();
  const { configuration } = useLoadedConfigurationState();
  const { getOrgLogo, setOrgLogo } = useOrgLogoStore();

  // setRefreshCnt is used to force the logo to refresh
  // as normally this only happens on initial load
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshCnt, setRefreshCnt] = useState<number>(0);

  useEffect(() => {
    if (selectedOrgId === configuration.solUUID) {
      return;
    }

    const existingLogo = getOrgLogo(selectedOrgId);
    if (existingLogo === undefined) {
      setOrgLogo(selectedOrgId, `${envConfig.api.orgLogos.baseUrl}logo-${selectedOrgId}`);
    }
  }, [
    accountFolioFieldNames.logo,
    api,
    configuration.solUUID,
    getOrgLogo,
    selectedOrgId,
    setOrgLogo,
  ]);

  const orgLogo = getOrgLogo(selectedOrgId);

  return {
    orgLogo: Some(orgLogo) ? orgLogo : (theme.images.orgLogo as string),
    refreshOrgLogo: (): void => {
      setRefreshCnt(prev => prev + 1);
    },
  };
};
