// Copyright 2025 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import type { Target } from "./types";
import type { ViewStyle } from "react-native";

type Props = {
  readonly targets?: readonly Target[];
};

export const TargetSection = ({ targets }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly cardContainer: ViewStyle;
  }>({
    cardContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.m,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: theme.spacing.m,
      marginVertical: theme.spacing.xs,
      padding: theme.spacing.m,
    },
  });

  if (targets === undefined) {
    return undefined;
  }

  return (
    <ScrollView>
      <FlatList
        contentContainerStyle={{ paddingBottom: theme.spacing.l }}
        data={targets}
        renderItem={({ index, item }) => (
          <View key={index} style={styles.cardContainer}>
            <Heading level="4">Group {index + 1}</Heading>
            <View>
              {item.merits.map((merit, meritIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <View key={meritIndex}>
                  <Body>- Has Merit {merit.name}</Body>
                  {merit.fieldValues?.map((fieldValue, fieldValuesIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Body key={fieldValuesIndex} style={{ marginLeft: theme.spacing.m }}>
                      - {fieldValue}
                    </Body>
                  ))}
                </View>
              ))}
            </View>
          </View>
        )}
      />
    </ScrollView>
  );
};
