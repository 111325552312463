// Copyright 2025 Merit International Inc. All Rights Reserved

import {
  Body,
  Button,
  DropdownMenu,
  Heading,
  Icon,
  LineSeparator,
  Link,
  MenuItemKind,
  useTheme,
} from "@merit/frontend-components";
import { Linking, Pressable, StyleSheet, View } from "react-native";
import { TargetSection } from "./TargetSection";
import { VerticalSpacer } from "@src/components";
import { offset, useFloating } from "@floating-ui/react-native";
import { useAlertStore } from "@src/stores";
import { useCallback, useMemo, useState } from "react";
import type { Opportunity } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

type Props = {
  readonly opportunity: Opportunity;
  readonly onPressAddGroup: () => void;
};

export const OpportunitiesItem = ({ onPressAddGroup, opportunity }: Props) => {
  const { theme } = useTheme();
  const {
    floatingStyles,
    refs: { setFloating, setReference },
  } = useFloating({
    middleware: [offset(theme.spacing.s)],
    placement: "top-start",
  });

  const { deleteAlert, setAlert } = useAlertStore();

  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);

  const styles = StyleSheet.create<{
    readonly badge: ViewStyle;
    readonly badges: ViewStyle;
    readonly container: ViewStyle;
    readonly jobCard: ViewStyle;
    readonly jobDetails: ViewStyle;
    readonly jobItem: ViewStyle;
    readonly link: TextStyle;
    readonly location: TextStyle;
    readonly targetContainer: ViewStyle;
    readonly targetHeaderContainer: ViewStyle;
    readonly verticalLine: ViewStyle;
    readonly addGroupButton: ViewStyle;
    readonly dropDownMenuContainer: ViewStyle;
  }>({
    addGroupButton: {
      alignSelf: "flex-end",
    },
    badge: {
      backgroundColor: theme.colors.background.default,
      borderRadius: theme.spacing.xs,
      padding: theme.spacing.s,
    },
    badges: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: theme.spacing.s,
      marginVertical: theme.spacing.m,
    },
    container: {
      flexDirection: "row",
      gap: theme.spacing.xl,
      marginRight: theme.spacing.l,
      marginVertical: theme.spacing.l,
    },
    dropDownMenuContainer: {
      ...floatingStyles,
      display: isDropdownMenuVisible ? "flex" : "none",
    },
    jobCard: {
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.spacing.s,
      borderWidth: 1,
      flex: 2,
      maxWidth: "60%",
    },
    jobDetails: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing.m,
      zIndex: -1,
    },
    jobItem: {
      alignItems: "center",
      width: "100%",
    },
    link: {
      color: theme.colors.border.highlight.default,
    },
    location: {
      color: theme.colors.text.subdued,
    },
    targetContainer: {
      // TODO(PF-1828): oceanBlue100 color is not available in the theme
      backgroundColor: "rgba(181,230,251,1)",
      borderRadius: theme.spacing.xxl,
      flex: 1,
      justifyContent: "space-between",
      padding: theme.spacing.l,
    },
    targetHeaderContainer: {
      alignItems: "center",
      display: "flex",
    },
    verticalLine: {
      // TODO(PF-1828):  black color is not available in the theme
      backgroundColor: "rgb(0, 0, 0)",
      height: 2,
      width: "80%",
    },
  });

  const onPress = useCallback(() => {
    Linking.openURL("https://google.com");
  }, []);

  const showAlertNotImplemented = useCallback(() => {
    setAlert({
      id: "notImplemented",
      onPressDelete: id => {
        deleteAlert(id);
      },
      text: "NotImplemented",
      type: "error",
    });
    setIsDropdownMenuVisible(false);
  }, [deleteAlert, setAlert]);

  const dropdownMenu = useMemo(
    () => (
      <View ref={setFloating} style={styles.dropDownMenuContainer}>
        <DropdownMenu
          menuItems={[
            {
              id: "publish",
              menuItemKind: MenuItemKind.Pressable,
              onPress: showAlertNotImplemented,
              text: "Publish",
            },
            {
              id: "delete",
              menuItemKind: MenuItemKind.Pressable,
              onPress: showAlertNotImplemented,
              pressableKind: "destructive",
              text: "Delete",
            },
          ]}
        />
      </View>
    ),
    [setFloating, showAlertNotImplemented, styles.dropDownMenuContainer]
  );

  return (
    <View style={styles.container}>
      <View style={styles.jobCard}>
        <View style={{ padding: theme.spacing.m }}>
          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Link onPress={onPress} size="l">
              <Heading level="4" numberOfLines={2}>
                {opportunity.title}
              </Heading>
            </Link>
            <Pressable
              onPress={() => {
                setIsDropdownMenuVisible(prev => !prev);
              }}
              ref={setReference}
            >
              <Icon name="menuVerticalMediumDefault" />
            </Pressable>
            {dropdownMenu}
          </View>
          <Body>{opportunity.company}</Body>
          <Body style={styles.location}>{opportunity.location}</Body>

          <View style={styles.badges}>
            <Body style={styles.badge}>{opportunity.salary}</Body>
            <Body style={styles.badge}>{opportunity.type}</Body>
            <Body style={styles.badge}>{opportunity.schedule}</Body>
          </View>

          {opportunity.description.map((line, index) => (
            <>
              {/*  eslint-disable-next-line react/no-array-index-key */}
              <Body key={index} numberOfLines={1}>
                • {line}
              </Body>
              <VerticalSpacer size={theme.spacing.xs} />
            </>
          ))}

          <Pressable>
            <Body onPress={onPress} style={styles.link}>
              More…
            </Body>
          </Pressable>
        </View>
        <LineSeparator />
        <Pressable style={{ padding: theme.spacing.m }}>
          <Link color={theme.colors.border.highlight.default} onPress={onPress}>
            View similar jobs with this employer
          </Link>
        </Pressable>
      </View>

      <View style={styles.jobDetails}>
        <View style={styles.jobItem}>
          <Heading level="5">ID</Heading>
          <Body>{opportunity.jobId}</Body>
        </View>
        <View style={styles.jobItem}>
          <Heading level="5">Date & Time created</Heading>
          <Body>{opportunity.date}</Body>
          <Body>{opportunity.time}</Body>
        </View>
        <View style={styles.jobItem}>
          <Heading level="5">Expiration Date & Time</Heading>
          <Body>{opportunity.date}</Body>
          <Body>{opportunity.time}</Body>
        </View>
        <View style={styles.jobItem}>
          <Heading level="5">Source</Heading>
          <Pressable onPress={onPress}>
            <Body>{opportunity.source}</Body>
          </Pressable>
        </View>
      </View>
      <View style={styles.targetContainer}>
        <View style={styles.targetHeaderContainer}>
          <Heading level="4">Targeting</Heading>
          <View style={styles.verticalLine} />
        </View>
        <View style={{ height: 150 }}>
          <TargetSection targets={opportunity.targets} />
        </View>
        <Button
          iconRight="closeCircleMediumDefault"
          onPress={onPressAddGroup}
          size="small"
          style={styles.addGroupButton}
          text="Add Group"
        />
      </View>
    </View>
  );
};
