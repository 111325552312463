// Copyright 2024 Merit International Inc. All Rights Reserved

export type FeatureFlags = {
  readonly allowOrgPortalAgentsCreate: boolean;
  readonly allowOrgPortalAgentsEdit: boolean;
  readonly allowOrgPortalAgentsPause: boolean;
  readonly showPolicyAnalytics: boolean;
  readonly showSigmaAnalytics: boolean;
  readonly showTemplateAnalytics: boolean;
  readonly showAdminPortalOpportunitiesFeature: boolean;
};

export const featureFlagDefaults: FeatureFlags = {
  allowOrgPortalAgentsCreate: false,
  allowOrgPortalAgentsEdit: false,
  allowOrgPortalAgentsPause: false,
  showAdminPortalOpportunitiesFeature: false,
  showPolicyAnalytics: false,
  showSigmaAnalytics: false,
  showTemplateAnalytics: true,
};
