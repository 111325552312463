// Copyright 2024 Merit International Inc. All Rights Reserved

import { Button, useTheme } from "@merit/frontend-components";
import { CONTENT_WIDTH } from "@src/components/NewNav/constants";
import { ConfirmationModal } from "@src/components/Modals";
import { Form, useFormikContext } from "formik";
import { FullScreenModalLayout } from "@src/layouts/FullScreenModalLayout";
import { SectionTemplateNameDescription } from "./SectionTemplateNameDescription";
import { SectionTemplatePermissions } from "./SectionTemplatePermissions";
import { StyleSheet, View } from "react-native";
import { TemplateRules } from "@src/components";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import type { FC } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { Policy } from "@src/gen/org-portal";
import type { PolicyValues } from "../types";
import type { RouteParams } from "@src/Router";

type PolicyFormProps = {
  readonly policyId?: Policy["id"];
};

export const PolicyForm: FC<PolicyFormProps> = ({ policyId }) => {
  const { theme } = useTheme();
  const ctx = useFormikContext<PolicyValues>();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const getTestProps = useGetTestProps();

  const closeScreen = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Policies");
  };

  const onClose = () => {
    if (ctx.dirty) {
      setShowConfirmationModal(true);
    } else {
      closeScreen();
    }
  };

  const styles = StyleSheet.create({
    actions: {
      alignItems: "center",
      flexDirection: "row",
      gap: theme.spacing.m,
      justifyContent: "flex-end",
      paddingBottom: theme.spacing.xxl,
      paddingRight: theme.spacing.xxl,
      paddingTop: theme.spacing.xxl,
    },
    contentContainer: {
      gap: theme.spacing.xl,
      marginHorizontal: "auto",
      padding: theme.spacing.xxl,
      width: CONTENT_WIDTH,
    },
    footer: {
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
    },
    horizontalRule: { borderTopColor: theme.colors.border.subdued, borderTopWidth: 1 },
  });

  return (
    <FullScreenModalLayout
      onClose={onClose}
      title={policyId === undefined ? "Create policy" : "Edit policy"}
    >
      <Form>
        <View style={styles.contentContainer}>
          <SectionTemplateNameDescription />
          <View style={styles.horizontalRule} />
          <TemplateRules title="Members will pass this policy if…" />
          <View style={styles.horizontalRule} />
          <SectionTemplatePermissions />
        </View>

        <View style={styles.footer}>
          <View style={styles.actions}>
            <Button onPress={onClose} text="Cancel" type="secondary" />
            <Button onPress={ctx.handleSubmit} text="Save" />
          </View>
        </View>

        {showConfirmationModal && (
          <ConfirmationModal
            onClose={() => {
              setShowConfirmationModal(false);
            }}
            onOk={() => {
              closeScreen();
              setShowConfirmationModal(false);
            }}
            {...getTestProps({
              elementName: `policiesConfirmCancel`,
            })}
            text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
            title="Unsaved changes"
            titleIconName="warningMediumCritical"
          />
        )}
      </Form>
    </FullScreenModalLayout>
  );
};
