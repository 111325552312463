import { Body, Heading, useTheme } from "@merit/frontend-components";
import { ErrorMessage } from "formik";
import { StyleSheet, View } from "react-native";
import { TextInputField } from "@src/components";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import type { FC } from "react";

type SectionTemplateNameDescriptionProps = unknown;

export const SectionTemplateNameDescription: FC<SectionTemplateNameDescriptionProps> = () => {
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create({
    container: {
      gap: theme.spacing.l,
    },
    passFailText: {
      padding: theme.spacing.xxl,
    },
    policyDescription: {
      gap: theme.spacing.xxs, // For error text
    },
    policyName: {
      gap: theme.spacing.xxs, // For error text
      maxWidth: 468,
    },
  });

  const errorText = (error: string, fieldName: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      <Body
        color={theme.colors.text.alert.critical}
        {...getTestProps({
          elementName: `template${fieldName}FieldError`,
        })}
      >
        {error}
      </Body>
    </View>
  );

  return (
    <View style={styles.container}>
      <Heading
        bold
        level="3"
        {...getTestProps({
          elementName: `PolicyHeaderText`,
        })}
      >
        Policy name and description
      </Heading>
      <View style={styles.policyName}>
        <TextInputField
          label="Name *"
          name="policyName"
          placeholder="Policy name"
          size="large"
          {...getTestProps({
            elementName: `NameTextInput`,
          })}
        />
        <ErrorMessage name="policyName">{error => errorText(error, "Name")}</ErrorMessage>
      </View>
      <View style={styles.policyDescription}>
        <TextInputField
          label="Description"
          name="policyDescription"
          numberOfLines={4}
          placeholder="Description"
          size="large"
          {...getTestProps({
            elementName: `DescriptionTextInput`,
          })}
        />
        <Heading
          level="6"
          {...getTestProps({
            elementName: `DescriptionMaxLimit`,
          })}
        >
          300 maximum character limit
        </Heading>
        <ErrorMessage name="policyDescription">
          {error => errorText(error, "Description")}
        </ErrorMessage>
      </View>
      <>
        <Heading
          level="4"
          {...getTestProps({
            elementName: `PassFailHeaderText`,
          })}
        >
          Policy Pass/Fail Responses
        </Heading>
        <Body
          {...getTestProps({
            elementName: `PassFailBodyText`,
          })}
          style={styles.passFailText}
        >
          Merit provides a generic policy response which cannot be edited. If a member passes
          validation, then “You passed this policy” will be displayed. If a member does not pass
          validation then, “You did not pass this policy. Contact your administrator.” will be
          displayed. Policy responses can be edited by agents/apps using the policy.{" "}
        </Body>
      </>
    </View>
  );
};
