// Copyright 2025 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { initDatadog } from "@src/utils/datadog";
import { useEffect, useState } from "react";
import Constants from "expo-constants";
import type { Configuration } from "@src/configuration";

export const useInitializeDatadog = () => {
  const config = Constants.expoConfig?.extra as Configuration;
  const { Some } = Helpers;
  const datadogRequired = Some(config.datadog.enabled);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isWorkDone, setIsWorkDone] = useState(false);

  useEffect(() => {
    (async () => {
      if (isWorkDone) {
        return;
      }
      if (datadogRequired && !isInitialized) {
        if (typeof config.datadog.applicationId !== "string") {
          // eslint-disable-next-line no-console
          console.error(
            "Datadog application ID must be defined as a string if datadog is enabled."
          );

          return;
        }
        if (typeof config.datadog.clientToken !== "string") {
          // eslint-disable-next-line no-console
          console.error("Datadog client token must be defined as a string if datadog is enabled.");

          return;
        }

        await initDatadog({
          allowedTracingUrls: config.datadog.allowedTracingUrls,
          applicationId: config.datadog.applicationId,
          clientToken: config.datadog.clientToken,
          environment: config.environmentSuffix ?? config.environment,
          serviceName: "org-portal-frontend",
        });
        setIsInitialized(true);
      }
      setIsWorkDone(true);
    })();
  }, [config, datadogRequired, isInitialized, isWorkDone]);

  return {
    isInitialized,
    isWorkDone,
  };
};
