// Copyright 2023 Merit International Inc. All Rights Reserved

import {
  Body,
  Button,
  Checkbox,
  Heading,
  Icon,
  Select,
  TextInput,
  useTheme,
} from "@merit/frontend-components";
import { CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum } from "../../../gen/org-portal";
import {
  DELIMITER_OPTIONS,
  GetDatasourceColumnTypes,
  INTEGRATION_TYPE_OPTIONS,
} from "../DatasourceColumnTypes";
import { FullScreenModalLayout } from "../../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { Pressable, ScrollView, View } from "react-native";
import { Spin } from "../../../components";
import { useAlertStore } from "../../../stores/alertStore";
import { useApi } from "../../../api/api";
import { useDatasourcesCreateScreenStyles } from "./styles";
import { useDatasourcesMutation } from "../useDatasourceMutation";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/core";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import type {
  CreateDatasourceRequest,
  CreateDatasourceRequestIntegrationTypeEnum,
  CreateDatasourceRequestSchemaColumnsInner,
  CreateDatasourceRequestSchemaDelimiterEnum,
} from "../../../gen/org-portal";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../../Router";
import type { SelectProps } from "@merit/frontend-components";

type Navigation = NativeStackNavigationProp<RouteParams, "CreateDatasource">;
type IntegrationType = CreateDatasourceRequestIntegrationTypeEnum;
type Delimiter = CreateDatasourceRequestSchemaDelimiterEnum;
type DataType = CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum;

const { Some } = Helpers;
const SCREEN_NAME = "DatasourceCreate";

type Payload = Omit<CreateDatasourceRequest, "schemaColumns"> & {
  readonly schemaColumns: readonly SchemaColumn[];
};

type SchemaColumn = Omit<CreateDatasourceRequestSchemaColumnsInner, "columnDataType"> & {
  readonly columnDataType: string;
  readonly key: string;
  readonly columnPhoneNumberDefaultCountryCode?: string;
};

const defaultPayload: Payload = {
  description: "",
  integrationType: "CSV",
  name: "",
  schemaColumns: [],
  schemaDelimiter: "comma",
};

const testProps = { elementName: SCREEN_NAME, screenName: SCREEN_NAME };

export const DatasourcesCreateScreen = () => {
  const navigation = useNavigation<Navigation>();
  const { theme } = useTheme();
  const styles = useDatasourcesCreateScreenStyles();
  const auth = useLoggedInAuthState();
  const alert = useAlertStore();
  const { api } = useApi();
  const [payload, setPayload] = useState(defaultPayload);

  const isUsingCSV = payload.integrationType === "CSV";
  const uniqueIdentifiers = payload.schemaColumns.filter(
    column => column.isIdentifier && column.columnName !== ""
  );

  const datasourceMutation = useDatasourcesMutation(api, auth.selectedOrgId);

  const updatePayload = (data: Partial<Payload>) => {
    setPayload({
      ...payload,
      ...data,
    });
  };

  const updateSchemaColumn = (
    index: number,
    data: Partial<CreateDatasourceRequestSchemaColumnsInner>
  ) => {
    const columns = payload.schemaColumns;

    updatePayload({
      schemaColumns: [
        ...columns.slice(0, index),
        {
          ...columns[index],
          ...data,
        },
        ...columns.slice(index + 1),
      ],
    });
  };

  const addSchemaColumn = () => {
    if (payload.schemaColumns.length >= 100) {
      alert.setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          alert.deleteAlert(id);
        },
        size: "medium",
        title: "Maximum number of columns is 100",
        type: "error",
      });

      return;
    }

    updatePayload({
      schemaColumns: [
        ...payload.schemaColumns,
        {
          columnDataType: "",
          columnName: "",
          columnPhoneNumberDefaultCountryCode: "US",
          isIdentifier: false,
          key: uuidv4(),
          usedAsDeletion: false,
        },
      ],
    });
  };

  const removeSchemaColumn = (index: number) => {
    const columns = payload.schemaColumns;

    updatePayload({
      schemaColumns: [...columns.slice(0, index), ...columns.slice(index + 1)],
    });
  };

  const computeDataFormatSelectProps = (
    column: Partial<SchemaColumn>,
    index: number
  ): Pick<SelectProps, "defaultValue" | "disabled" | "onSelectOption" | "options"> => {
    switch (column.columnDataType) {
      case "PhoneNumber":
        return {
          defaultValue: { label: "US", value: "US" },
          disabled: true,
          onSelectOption: item => {
            updateSchemaColumn(index, {
              columnPhoneNumberDefaultCountryCode: `${item.value}`,
            });
          },
          options: [{ label: "US", value: "US" }],
        };

      case "Date":
        return {
          disabled: false,
          onSelectOption: item => {
            updateSchemaColumn(index, {
              columnDateFormat: `${item.value}`,
            });
          },
          options: [
            { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
            { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
          ],
        };

      case "DateTime":
        return {
          disabled: false,
          onSelectOption: item => {
            updateSchemaColumn(index, {
              columnDateFormat: `${item.value}`,
            });
          },
          options: [
            { label: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
            { label: "MM-DD-YYYY HH:mm:ss", value: "MM-DD-YYYY HH:mm:ss" },
          ],
        };

      default:
        return {
          defaultValue: { label: "Format", value: "" },
          disabled: true,
          options: [],
        };
    }
  };

  const submitPayload = async () => {
    await datasourceMutation.create(
      {
        ...payload,
        schemaColumns: payload.schemaColumns.map<CreateDatasourceRequestSchemaColumnsInner>(
          column => ({
            columnDataType: column.columnDataType as DataType,
            columnDateFormat: column.columnDateFormat,
            columnName: column.columnName,
            columnPhoneNumberDefaultCountryCode: column.columnPhoneNumberDefaultCountryCode,
            isIdentifier: column.isIdentifier,
            usedAsDeletion: column.usedAsDeletion,
          })
        ),
      },
      () => {
        setPayload(defaultPayload);
        navigation.navigate("Datasources");
      }
    );
  };

  const datasourceColumnTypes = GetDatasourceColumnTypes(false);
  const usedAsDeletionColumnTypes = GetDatasourceColumnTypes(true);

  return (
    <FullScreenModalLayout
      onClose={() => {
        setPayload(defaultPayload);

        navigation.navigate("Datasources");
      }}
      testProps={testProps}
      title="Create data source"
    >
      <View style={styles.container}>
        <ScrollView>
          <Spin spinning={datasourceMutation.loading}>
            <View style={styles.content}>
              <Heading
                bold
                level="3"
                testProps={{
                  elementName: Some(testProps) ? `${testProps.elementName}Heading` : "",
                  screenName: SCREEN_NAME,
                }}
              >
                Data source name and type
              </Heading>

              <VerticalSpacer size={theme.spacing.xxl} />

              <View style={{ maxWidth: 468 }}>
                <TextInput
                  label="Name *"
                  onChangeText={value => {
                    updatePayload({ name: value });
                  }}
                  placeholder="Name"
                  showErrorIcon={datasourceMutation.getValidationError("name")}
                  testProps={{
                    elementName: Some(testProps)
                      ? `${testProps.elementName}NameTextInput`
                      : "NameTextInput",
                    screenName: SCREEN_NAME,
                  }}
                  value={payload.name}
                />
              </View>

              <VerticalSpacer size={theme.spacing.xxl} />

              <TextInput
                label="Description"
                maxLength={300}
                numberOfLines={4}
                onChangeText={value => {
                  updatePayload({ description: value });
                }}
                placeholder="Description"
                testProps={{
                  elementName: Some(testProps)
                    ? `${testProps.elementName}DescriptionTextInput`
                    : "DescriptionTextInput",
                  screenName: SCREEN_NAME,
                }}
                value={payload.description}
              />
              <VerticalSpacer size={theme.spacing.s} />
              <Body testProps={testProps}>300 maximum character limit</Body>

              <VerticalSpacer size={theme.spacing.xxl} />

              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                  <Select
                    defaultValue={INTEGRATION_TYPE_OPTIONS.find(
                      item => item.value === payload.integrationType
                    )}
                    disabled
                    label="Integration type *"
                    onSelectOption={item => {
                      updatePayload({ integrationType: item.value as IntegrationType });
                    }}
                    options={INTEGRATION_TYPE_OPTIONS}
                    testProps={{
                      elementName: Some(testProps)
                        ? `${testProps.elementName}IntegrationTypeSelect`
                        : "IntegrationTypeSelect",
                      screenName: SCREEN_NAME,
                    }}
                    usePortal
                  />
                </View>

                <HorizontalSpacer size={theme.spacing.xxl} />

                <View style={{ flex: 1 }}>
                  {isUsingCSV && (
                    <>
                      <TextInput
                        disabled
                        label="Preset adapter type"
                        testProps={{
                          elementName: Some(testProps)
                            ? `${testProps.elementName}AdapterTypeTextInput`
                            : "AdapterTypeTextInput",
                          screenName: SCREEN_NAME,
                        }}
                        value="CSV and other flat files"
                      />
                      <VerticalSpacer size={theme.spacing.s} />
                      <Body>Can support CSV, TSV, and TXT files</Body>
                    </>
                  )}
                </View>
              </View>

              {isUsingCSV && (
                <>
                  <View style={styles.separator} />

                  <Heading
                    bold
                    level="3"
                    testProps={{
                      elementName: Some(testProps)
                        ? `${testProps.elementName}SchemaHeading`
                        : "SchemaHeading",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    Schema
                  </Heading>

                  <VerticalSpacer size={theme.spacing.xxl} />

                  <View style={styles.schemaHeaderRow}>
                    <View style={{ width: 208 }}>
                      <Select
                        defaultValue={DELIMITER_OPTIONS.find(
                          item => item.value === payload.schemaDelimiter
                        )}
                        label="Delimiter"
                        onSelectOption={item => {
                          updatePayload({ schemaDelimiter: item.value as Delimiter });
                        }}
                        options={DELIMITER_OPTIONS}
                        testProps={{
                          elementName: Some(testProps) ? `${testProps.elementName}Select` : "",
                          screenName: SCREEN_NAME,
                        }}
                        usePortal
                      />
                    </View>
                    <HorizontalSpacer size={32} />
                    <View style={{ flex: 1 }}>
                      <Heading
                        level="5"
                        testProps={{
                          elementName: Some(testProps) ? `${testProps.elementName}Heading` : "",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Unique identifier(s)
                      </Heading>
                      <View style={{ flex: 1, justifyContent: "center" }}>
                        {uniqueIdentifiers.length === 0 ? (
                          <Body testProps={testProps}>No identifier selected</Body>
                        ) : (
                          <View style={styles.tags}>
                            {uniqueIdentifiers.map(item => (
                              <View key={item.key} style={styles.tag}>
                                <Body>{item.columnName}</Body>
                              </View>
                            ))}
                          </View>
                        )}
                      </View>
                    </View>

                    <View style={{ justifyContent: "center" }}>
                      <Button
                        iconLeft="addSmallDefault"
                        onPress={() => {
                          addSchemaColumn();
                        }}
                        size="small"
                        testProps={{
                          elementName: Some(testProps) ? `${testProps.elementName}Button` : "",
                          screenName: SCREEN_NAME,
                        }}
                        text="Add column"
                        type="secondary"
                      />
                    </View>
                  </View>

                  <VerticalSpacer size={theme.spacing.xxl} />

                  <View style={styles.schemaContainer}>
                    <View style={styles.schemaTableRow}>
                      <View style={styles.schemaColumnName}>
                        <Heading
                          level="5"
                          testProps={{
                            elementName: Some(testProps)
                              ? `${testProps.elementName}ColumnNameHeading`
                              : "ColumnNameHeading",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          Column name
                        </Heading>
                      </View>
                      <View style={styles.schemaColumnDatatype}>
                        <Heading
                          level="5"
                          testProps={{
                            elementName: Some(testProps)
                              ? `${testProps.elementName}DataTypeHeading`
                              : "DataTypeHeading",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          Data type
                        </Heading>
                      </View>
                      <View style={styles.schemaColumnFormat}>
                        <Heading
                          level="5"
                          testProps={{
                            elementName: Some(testProps)
                              ? `${testProps.elementName}FormatHeading`
                              : "FormatHeading",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          Format
                        </Heading>
                      </View>
                      <View style={styles.schemaColumnIdentifier}>
                        <Heading
                          level="5"
                          testProps={{
                            elementName: Some(testProps)
                              ? `${testProps.elementName}UniqueIdentifierHeading`
                              : "UniqueIdentifierHeading",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          Unique identifier
                        </Heading>
                      </View>
                      <View style={styles.schemaColumnRemoval}>
                        <Heading
                          level="5"
                          testProps={{
                            elementName: Some(testProps)
                              ? `${testProps.elementName}RemovalColumnHeading`
                              : "RemovalColumnHeading",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          Removal
                        </Heading>
                      </View>

                      <View style={styles.schemaColumnX} />
                    </View>

                    {payload.schemaColumns.map((column, index) => (
                      <View key={column.key} style={styles.schemaTableRow}>
                        <View style={styles.schemaColumnName}>
                          <TextInput
                            autoFocus
                            maxLength={120}
                            onChangeText={value => {
                              updateSchemaColumn(index, { columnName: value });
                            }}
                            placeholder="Name"
                            showErrorIcon={datasourceMutation.getValidationError(
                              `schemaColumns[${index}].columnName`
                            )}
                            testProps={{
                              elementName: Some(testProps)
                                ? `${testProps.elementName}NameTextInput-${index}`
                                : `NameTextInput-${index}`,
                              screenName: SCREEN_NAME,
                            }}
                            value={payload.schemaColumns[index].columnName}
                          />
                        </View>

                        <View style={styles.schemaColumnDatatype}>
                          <Select
                            defaultValue={{ label: "Data type", value: "" }}
                            label="Data type"
                            onSelectOption={item => {
                              updateSchemaColumn(index, {
                                columnDataType: item.value as DataType,
                                columnDateFormat: undefined,
                              });
                            }}
                            options={
                              Boolean(column.usedAsDeletion)
                                ? usedAsDeletionColumnTypes
                                : datasourceColumnTypes
                            }
                            showLabel={false}
                            testProps={{
                              elementName: Some(testProps)
                                ? `${testProps.elementName}DataTypeSelect-${index}`
                                : `DataTypeSelect-${index}`,
                              screenName: SCREEN_NAME,
                            }}
                            usePortal
                          />
                        </View>

                        <View style={styles.schemaColumnFormat}>
                          <Select
                            defaultValue={{ label: "Format", value: "" }}
                            key={payload.schemaColumns[index].columnDataType}
                            label="Format"
                            showLabel={false}
                            usePortal
                            {...computeDataFormatSelectProps(column, index)}
                            testProps={{
                              elementName: Some(testProps)
                                ? `${testProps.elementName}FormatSelect-${index}`
                                : `FormatSelect-${index}`,
                              screenName: SCREEN_NAME,
                            }}
                          />
                        </View>

                        <View style={styles.schemaColumnIdentifier}>
                          <Checkbox
                            defaultChecked={payload.schemaColumns[index].isIdentifier}
                            disabled={payload.schemaColumns[index].usedAsDeletion}
                            onChange={value => {
                              updateSchemaColumn(index, { isIdentifier: value });
                            }}
                            testProps={{
                              elementName: Some(testProps)
                                ? `${testProps.elementName}UniqueIdentifierCheckBox-${index}`
                                : `UniqueIdentifierCheckBox-${index}`,
                              screenName: SCREEN_NAME,
                            }}
                          />
                        </View>
                        <View style={styles.schemaColumnRemoval}>
                          <Checkbox
                            defaultChecked={payload.schemaColumns[index].usedAsDeletion}
                            disabled={
                              !payload.schemaColumns[index].usedAsDeletion &&
                              (payload.schemaColumns[index].isIdentifier ||
                                column.columnDataType !==
                                  CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Bool ||
                                payload.schemaColumns.some(
                                  schemaColumn => schemaColumn.usedAsDeletion
                                ))
                            }
                            onChange={value => {
                              updateSchemaColumn(index, { usedAsDeletion: value });
                            }}
                            testProps={{
                              elementName: Some(testProps)
                                ? `${testProps.elementName}RemovalCheckBox-${index}`
                                : `RemovalCheckBox-${index}`,
                              screenName: SCREEN_NAME,
                            }}
                          />
                        </View>

                        <View style={styles.schemaColumnX}>
                          <Pressable
                            onPress={() => {
                              removeSchemaColumn(index);
                            }}
                          >
                            <Icon
                              name="closeSmallDefault"
                              testProps={{
                                elementName: Some(testProps)
                                  ? `${testProps.elementName}CloseIcon-${index}`
                                  : `CloseIcon-${index}`,
                                screenName: SCREEN_NAME,
                              }}
                            />
                          </Pressable>
                        </View>
                      </View>
                    ))}
                  </View>

                  <VerticalSpacer size={theme.spacing.xxl} />

                  <View style={{ alignItems: "flex-start" }}>
                    <Button
                      iconLeft="addSmallDefault"
                      onPress={() => {
                        addSchemaColumn();
                      }}
                      size="small"
                      testProps={{
                        elementName: Some(testProps)
                          ? `${testProps.elementName}AddColumnButton`
                          : "AddColumnButton",
                        screenName: SCREEN_NAME,
                      }}
                      text="Add column"
                      type="secondary"
                    />
                  </View>

                  <VerticalSpacer size={200} />
                </>
              )}
            </View>
          </Spin>
        </ScrollView>

        <View style={styles.footer}>
          <Button
            onPress={() => {
              submitPayload();
            }}
            size="large"
            testProps={{
              elementName: Some(testProps)
                ? `${testProps.elementName}CreateButton`
                : "CreateButton",
              screenName: SCREEN_NAME,
            }}
            text="Create"
          />
        </View>
      </View>
    </FullScreenModalLayout>
  );
};
