// Copyright 2025 Merit International Inc. All Rights Reserved

import { datadogRum as DdRum } from "@datadog/browser-rum";
import { FlagEffect } from "./FlagEffect";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import type { LDEvaluationDetail, ProviderConfig } from "launchdarkly-react-client-sdk";
import type { LDMultiKindContext } from "launchdarkly-js-client-sdk";
import type { ProviderInitializeFunction } from "../types";

type Options = {
  readonly entityID: string | undefined;
  readonly launchDarklyClientID: string;
  readonly selectedOrgId: string | null;
};

export const initialize: ProviderInitializeFunction<Options> = async ({
  entityID,
  launchDarklyClientID,
  selectedOrgId,
}) => {
  const context: LDMultiKindContext = {
    kind: "multi",
    org: {
      key: selectedOrgId ?? undefined,
    },
    user: {
      key: entityID,
    },
  };

  const ldProviderConfig: ProviderConfig = {
    clientSideID: launchDarklyClientID,
    context: entityID === undefined || selectedOrgId === null ? undefined : context,
    options: {
      inspectors: [
        {
          method: (key: string, detail: LDEvaluationDetail) => {
            DdRum.addFeatureFlagEvaluation(key, detail.value);
          },
          name: "dd-inspector",
          type: "flag-used",
        },
      ],
    },
  };

  const LDProvider = await asyncWithLDProvider(ldProviderConfig);

  return ({ children }) => (
    <LDProvider>
      <FlagEffect />
      {children}
    </LDProvider>
  );
};
