// Copyright 2025 Merit International Inc. All Rights Reserved

import { useConfigurationStore } from "@src/stores";
import { useEffect } from "react";

export const useInitializeConfiguration = () => {
  const { configuration, updateConfiguration } = useConfigurationStore();
  const isInitialized = configuration !== undefined;

  useEffect(() => {
    if (!isInitialized) {
      updateConfiguration();
    }
  }, [isInitialized, updateConfiguration]);

  return { isInitialized };
};
