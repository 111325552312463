// Copyright 2025 Merit International Inc. All Rights Reserved
/* eslint-disable @typescript-eslint/no-require-imports */

import { useFonts } from "expo-font";

export const useInitializeFonts = () => {
  const [isInitialized] = useFonts({
    ProximaNova: require("../../assets/fonts/ProximaNova.otf"),
    ProximaNovaBold: require("../../assets/fonts/ProximaNovaBold.otf"),
    ProximaNovaMedium: require("../../assets/fonts/ProximaNovaMedium.otf"),
    ProximaNovaSemiBold: require("../../assets/fonts/ProximaNovaSemiBold.otf"),
  });

  return { isInitialized };
};
