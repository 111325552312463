// Copyright 2022 Merit International Inc. All Rights Reserved.

import { AssertIsDefined, AssertIsString } from "../utils/asserts";
import { useAuthStore } from "../stores/authStore";
import type { JwtClaims } from "@src/types/auth";

export type AuthState = {
  readonly userCanRegisterOrgs: boolean;
  readonly selectedOrgId: string;
  readonly selectedOrgName: string;
  readonly accessToken: string;
  readonly permissions: readonly string[] | null;
  readonly session: string;
  readonly profile: JwtClaims;
};

export const useLoggedInAuthState = (): AuthState => {
  const { accessToken, permissions, profile, selectedOrgId, selectedOrgName, session } =
    useAuthStore();

  AssertIsDefined(profile, "Profile must not be null");
  AssertIsDefined(permissions, "Permissions must not be null");

  AssertIsString(accessToken, "Access token must be a string");
  AssertIsString(selectedOrgId, "Selected org id must not be null");
  AssertIsString(selectedOrgName, "Selected org name must not be null");
  AssertIsString(session, "Session must not be null");

  const userCanRegisterOrgs = profile.name.includes("@gomerits.com");

  return {
    accessToken,
    permissions,
    profile,
    selectedOrgId,
    selectedOrgName,
    session,
    userCanRegisterOrgs,
  };
};
