// Copyright 2024 Merit International Inc. All Rights Reserved

/*
 Utilities for converting between platform policy data structures and our policy data structure
*/

import {
  CompoundAtomPredicateEnum,
  ExtendPolicyRequestFormulaFormulaTypeEnum,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum,
} from "@src/gen/org-portal";
import { Helpers } from "@merit/frontend-utils";
import { PredicateNeedsThreeValuesMap, PredicateNeedsValueSet } from "@src/components";
import { v4 as uuidv4 } from "uuid";
import type { ApiPolicy } from "@src/api/api";
import type {
  ExtendEditPolicyUnionType,
  FieldLevelPredicate,
  FieldRule,
  TemplateRule,
  TemplateStatusOptions,
} from "@src/components";
import type {
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner,
  GetPolicies200ResponsePoliciesInnerResponseFormulasOwn,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum,
} from "@src/gen/org-portal";
import type { PolicyValues } from "../CreatePolicy/types";

const policyPermissionsToPlatformPermissions = (
  policyPerms: PolicyValues["policyPermissions"]
  // eslint-disable-next-line functional/prefer-readonly-type
): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner[] => {
  const readAction =
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum.Read;
  const extendAction =
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum.Extend;
  const othersView = policyPerms.othersView === "yes";
  const othersUse = policyPerms.othersUse === "yes";

  return [
    {
      action: readAction,
      permissibleToPermit: { action: readAction, grantedToName: "None" },
      permitted: { action: readAction, grantedToName: othersView ? "All" : "None" },
    },
    {
      action: extendAction,
      permissibleToPermit: { action: extendAction, grantedToName: "None" },
      permitted: { action: extendAction, grantedToName: othersUse ? "All" : "None" },
    },
  ];
};

// eslint-disable-next-line functional/no-class
export class InvalidPolicyError extends Error {}
// eslint-disable-next-line functional/no-class
export class PolicyParsingError extends Error {}

const { None, Some } = Helpers;

const ParseArguments = (
  predicate: FieldLevelPredicate,
  value: string | undefined
): readonly string[] => {
  if (PredicateNeedsThreeValuesMap.has(predicate)) {
    if (None(value)) {
      throw new InvalidPolicyError(`Invalid arguments list for predicate: ${predicate}`);
    }
    const func = PredicateNeedsThreeValuesMap.get(predicate);
    if (None(func)) {
      throw new PolicyParsingError(`Unexpected predicate: ${predicate}`);
    }

    return func(value);
  }

  if (PredicateNeedsValueSet.has(predicate)) {
    if (None(value)) {
      throw new InvalidPolicyError(`Invalid argument for predicate: ${predicate}`);
    }

    return [value];
  }

  if (None(value)) {
    return [];
  }

  return [value];
};

const fieldRuleToCompoundAtom = (
  rule: FieldRule
): GetPolicies200ResponsePoliciesInnerResponseFormulasOwn => {
  if (None(rule.predicate)) {
    throw new InvalidPolicyError("predicate must be defined.");
  }

  return {
    arguments: ParseArguments(rule.predicate, rule.value),
    errorMessage: "error matching field",
    formulaType: "CompoundAtom",
    predicate: rule.predicate,
    target: rule.templateFieldId,
  } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
};

const templateRuleToTemplateFormula = (
  rule: TemplateRule
): GetPolicies200ResponsePoliciesInnerResponseFormulasOwn => {
  const status: TemplateStatusOptions = rule.templateStatus;
  const outer = {
    arguments: ["1", rule.templateId, status === "any" ? "both" : status],
    errorMessage: "Error matching template",
    formulaType: "CompoundAtom",
    predicate: "ReceivedXContainersFromTemplates",
  } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
  if (rule.fieldRules.length === 0) {
    return outer;
  }
  // we know that there's at least one field rule
  // so we have to wrap with a compound atom with the ReceivedXContainersFromTemplates predicate

  if (rule.fieldRules.length > 1) {
    if (rule.fieldCombinationType === "any") {
      return {
        formula: {
          disjunction: rule.fieldRules.map(fieldRule => fieldRuleToCompoundAtom(fieldRule)),
          formulaType: "Disjunction",
        },
        ...outer,
      } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
    }

    return {
      formula: {
        conjunction: rule.fieldRules.map(fieldRule => fieldRuleToCompoundAtom(fieldRule)),
        formulaType: "Conjunction",
      },
      ...outer,
    } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
  }

  if (None(rule.fieldRules[0].predicate)) {
    throw new InvalidPolicyError("predicate must be defined");
  }

  return {
    formula: {
      arguments: ParseArguments(rule.fieldRules[0].predicate, rule.fieldRules[0].value),
      errorMessage: "error matching field",
      predicate: rule.fieldRules[0].predicate,
      target: rule.fieldRules[0].templateFieldId,
    },
    ...outer,
  } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
};

export const policyToPlatformPolicy = (policy: PolicyValues): ExtendEditPolicyUnionType => {
  const nestedFormula = policy.templateRules.map(rule => templateRuleToTemplateFormula(rule));
  const formula =
    policy.ruleCombinationType === "any"
      ? {
          disjunction: nestedFormula,
          formulaType: ExtendPolicyRequestFormulaFormulaTypeEnum.Disjunction,
        }
      : {
          conjunction: nestedFormula,
          formulaType: ExtendPolicyRequestFormulaFormulaTypeEnum.Conjunction,
        };

  return {
    description: policy.policyDescription,
    falseMessage: "You did not pass this policy, contact your administrator.",
    formula,
    name: policy.policyName,
    permissions: policyPermissionsToPlatformPermissions(policy.policyPermissions),
    rule: [],
    trueMessage: "You passed this policy.",
  };
};

const apiPermissionsToFormPermissions = (
  apiPerms: readonly GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner[]
): PolicyValues["policyPermissions"] => {
  const readPerm = apiPerms.find(f => f.action === "read");
  const extendPerm = apiPerms.find(f => f.action === "extend");
  const othersView = readPerm?.permitted.grantedToName === "All" ? "yes" : "no";
  const othersUse = extendPerm?.permitted.grantedToName === "All" ? "yes" : "no";

  return {
    othersUse,
    othersView,
  };
};

const combinationTbl: Record<string, PolicyValues["ruleCombinationType"]> = {
  CompoundAtom: "all",
  Conjunction: "all",
  Disjunction: "any",
};

const templateStatusTbl: Record<string, TemplateStatusOptions> = {
  active: "active",
  both: "any",
  inactive: "inactive",
};

const apiFieldRuleToFieldRules = (
  formula: ApiPolicy["responseFormulas"]["own"]
): readonly FieldRule[] => {
  if (formula.formulaType === "CompoundAtom") {
    if (formula.arguments.length > 1) {
      throw new InvalidPolicyError(
        `Expected zero or one arguments to predicate ${formula.predicate}, but got ${formula.arguments.length}`
      );
    }

    if (!(formula.predicate in CompoundAtomPredicateEnum)) {
      throw new InvalidPolicyError(`Expected a valid predicate but got ${formula.predicate}`);
    }

    return [
      {
        id: uuidv4(),
        predicate:
          formula.predicate as OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum,
        templateFieldId: formula.target,
        value: formula.arguments[0],
      },
    ];
  } else if (formula.formulaType === "Conjunction") {
    return formula.conjunction.flatMap(apiFieldRuleToFieldRules);
  }

  return formula.disjunction.flatMap(apiFieldRuleToFieldRules);
};

const apiFormulaRuleToTemplateRule = (
  formulaRule: ApiPolicy["responseFormulas"]["own"]
): TemplateRule => {
  if (formulaRule.formulaType === "CompoundAtom") {
    const args = formulaRule.arguments;
    const targetTemplate = args[1];
    if (args.length < 2) {
      throw new InvalidPolicyError(`Expected a template ID in template rule`);
    }
    const templateStatusStr = args.length < 3 ? "both" : args[2];
    if (!(templateStatusStr in templateStatusTbl)) {
      throw new InvalidPolicyError(`Expected a valid template status but got ${templateStatusStr}`);
    }

    return {
      fieldCombinationType: Some(formulaRule.formula)
        ? combinationTbl[formulaRule.formula.formulaType]
        : "all",
      fieldRules: Some(formulaRule.formula) ? apiFieldRuleToFieldRules(formulaRule.formula) : [],
      fieldValuesType: Some(formulaRule.formula) ? "specific" : "any",
      id: uuidv4(),
      templateId: targetTemplate,
      templateStatus: templateStatusTbl[templateStatusStr],
    };
  }

  throw new InvalidPolicyError(
    `Expected template rules to have a CompoundAtom, got ${formulaRule.formulaType}`
  );
};

export const apiPolicyToFormPolicy = (apiPolicy: ApiPolicy): PolicyValues => {
  const toplevelFormula = apiPolicy.responseFormulas;

  const toplevelCombinationType =
    Some(toplevelFormula) && Some(toplevelFormula.own.formulaType)
      ? toplevelFormula.own.formulaType
      : "Conjunction";

  // TODO: no support for inherited rules yet

  if (toplevelFormula.own.formulaType === "CompoundAtom") {
    throw new InvalidPolicyError("Expected Disjunction or Conjunction at toplevel of policy");
  }

  const templateRules =
    toplevelFormula.own.formulaType === "Conjunction"
      ? toplevelFormula.own.conjunction.map(apiFormulaRuleToTemplateRule)
      : toplevelFormula.own.disjunction.map(apiFormulaRuleToTemplateRule);

  return {
    policyDescription: apiPolicy.description,
    policyName: apiPolicy.name,
    policyPermissions: apiPermissionsToFormPermissions(apiPolicy.permissions ?? []),
    ruleCombinationType: combinationTbl[toplevelCombinationType],
    templateRules,
  };
};
