import { Body, Heading, useTheme } from "@merit/frontend-components";
import { SelectField, Tooltip } from "@src/components";
import { StyleSheet, View } from "react-native";
import { findOptionByValue, onLabel } from "../utils";
import { useFormikContext } from "formik";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import type { FC } from "react";
import type { Option } from "@src/components";
import type { PolicyValues } from "../types";

type SectionTemplatePermissionsProps = unknown;

const permissionOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
] as readonly Option[];

export const SectionTemplatePermissions: FC<SectionTemplatePermissionsProps> = () => {
  const { theme } = useTheme();
  const ctx = useFormikContext<PolicyValues>();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create({
    permissionFields: { gap: theme.spacing.m, width: 468 },
  });

  return (
    <>
      <Heading
        bold
        level="3"
        {...getTestProps({
          elementName: `PermissionsText`,
        })}
      >
        Permissions
      </Heading>
      <Body
        {...getTestProps({
          elementName: `DescriptionText`,
        })}
      >
        Select the permissions for this policy
      </Body>
      <View style={styles.permissionFields}>
        <SelectField
          defaultValue={findOptionByValue(
            permissionOptions,
            ctx.values.policyPermissions.othersView
          )}
          filterOption={onLabel}
          label="Can others view this?"
          name="policyPermissions.othersView"
          onSelectOption={option => {
            if (option.value === "no") {
              ctx.setFieldValue("policyPermissions.othersUse", "no");
            }
          }}
          options={permissionOptions}
          rightElement={
            <Tooltip
              icon="informationActive"
              {...getTestProps({
                elementName: `ReadPermissionTooltip`,
              })}
              text="View permissions give organizations the ability to view this policy."
            />
          }
          size="large"
          {...getTestProps({
            elementName: `ReadPermission`,
          })}
          usePortal
        />
        <SelectField
          defaultValue={findOptionByValue(
            permissionOptions,
            ctx.values.policyPermissions.othersUse
          )}
          disabled={ctx.values.policyPermissions.othersView === "no"}
          filterOption={onLabel}
          key={ctx.values.policyPermissions.othersView}
          label="Can others use this?"
          name="policyPermissions.othersUse"
          options={permissionOptions}
          rightElement={
            <Tooltip
              icon="informationActive"
              {...getTestProps({
                elementName: `ExtendPermissionTooltip`,
              })}
              text="Extend permissions give organizations the ability to use and extend this policy to other connected organizations."
            />
          }
          size="large"
          {...getTestProps({
            elementName: `ExtendPermission`,
          })}
          usePortal
        />
      </View>
    </>
  );
};
