// Copyright 2024 Merit International Inc. All Rights Reserved

import {
  InvalidPolicyError,
  PolicyParsingError,
  policyToPlatformPolicy,
} from "@src/screens/Policies/utils";
import { PolicyFormikWrapper } from "./components";
import { StyleSheet, View } from "react-native";
import { useAlertStore } from "@src/stores";
import { useApi } from "@src/api/api";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import type { ExtendPolicyOperationRequest } from "@src/gen/org-portal";
import type { FC } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PolicyValues } from "./types";
import type { RouteParams } from "@src/Router";

export const CreatePolicy: FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { theme } = useTheme();
  const { policiesApi } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const { selectedOrgId } = useLoggedInAuthState();
  const config = useLoadedConfigurationState();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  const showAlert = (errorID: string, errorMessage: string) => {
    setAlert({
      closable: true,
      id: errorID,
      onPressDelete: id => {
        deleteAlert(id);
      },
      text: `Invalid policy: ${String(errorMessage)}`,
      type: "error",
    });
  };

  const createPolicy = (policy: PolicyValues) => {
    const submitPolicy = async () => {
      try {
        const platformPolicy = policyToPlatformPolicy(policy);
        const extendPolicyParams: ExtendPolicyOperationRequest = {
          orgID: selectedOrgId,
          properties: {
            sourcePolicyID: config.configuration.basePolicyUUID,
            ...platformPolicy,
          },
        };

        await policiesApi.extendPolicy(extendPolicyParams);
        navigation.navigate("Policies");
      } catch (err) {
        if (err instanceof InvalidPolicyError) {
          showAlert("invalid-policy-error", `Invalid policy: ${String(err)}`);
        } else if (err instanceof PolicyParsingError) {
          showAlert("policy-validation-error", `Error validating policy: ${String(err)}`);
        } else {
          setAlert({
            closable: true,
            id: "policy-submit-error",
            onPressDelete: id => {
              deleteAlert(id);
            },
            text: `Error occurred saving policy: ${String(err)}`,
            type: "error",
          });
        }
      }
    };
    submitPolicy();
  };

  return (
    <View style={styles.container}>
      <PolicyFormikWrapper onSubmit={createPolicy} />
    </View>
  );
};
