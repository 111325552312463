// Copyright 2025 Merit International Inc. All Rights Reserved

import { useFeatureFlagsStore } from "@src/stores/featureFlagsStore";
import type { FeatureFlags } from "@src/configuration/featureFlags";

type UseFeatureFlag = <T extends keyof FeatureFlags>(name: T) => FeatureFlags[T];

export const useFeatureFlag: UseFeatureFlag = name => {
  const getFeatureFlag = useFeatureFlagsStore(state => state.getFeatureFlag);

  return getFeatureFlag(name);
};
