export const OpportunitiesMockData = [
  {
    company: "Thermo Fisher Scientific",
    date: "02/27/2025",
    description: [
      "At Thermo Fisher Scientific, you’ll discover meaningful work that makes a positive impact on a global scale.",
      "Join our colleagues in bringing our Mission to life...",
    ],
    id: 1,
    jobId: "82J73",
    location: "Remote in New York, NY",
    salary: "$200,000 - $278,900 a year",
    schedule: "Monday to Friday",
    source: "Indeed",
    targets: [
      {
        merits: [
          {
            fieldValues: ["field A value > 10"],
            name: "X",
          },
        ],
      },
      {
        merits: [
          {
            fieldValues: ["field A value > 10"],
            name: "Y",
          },
        ],
      },
    ],
    time: "04:22:15 PM",
    title: "Medical Director/Global Medical Indication Lead - Rheumatology (FSP)",
    type: "Full-time",
  },
  {
    company: "Pfizer",
    date: "03/05/2025",
    description: [
      "Be a part of a team that delivers high-quality pharmaceutical solutions.",
      "Support our clients with innovative sales strategies.",
    ],
    id: 2,
    jobId: "45T89",
    location: "Boston, MA",
    salary: "$150,000 - $200,000 a year",
    schedule: "Weekends Only",
    source: "LinkedIn",
    targets: [
      {
        merits: [
          {
            fieldValues: ["field A value > 10"],
            name: "X",
          },
        ],
      },
      {
        merits: [
          {
            fieldValues: ["field A value > 10"],
            name: "X",
          },
          {
            fieldValues: ["field A value > 10", "field A value > 20"],
            name: "Y",
          },
        ],
      },
    ],
    time: "10:15:00 AM",
    title: "Clinical Sales Specialist",
    type: "Part-time",
  },
  {
    company: "Pfizer",
    date: "03/05/2025",
    description: [
      "Be a part of a team that delivers high-quality pharmaceutical solutions.",
      "Support our clients with innovative sales strategies.",
    ],
    id: 3,
    jobId: "46T89",
    location: "Boston, MA",
    salary: "$150,000 - $200,000 a year",
    schedule: "Weekends Only",
    source: "LinkedIn",
    time: "10:15:00 AM",
    title: "Clinical Sales Specialist",
    type: "Part-time",
  },
  {
    company: "Pfizer",
    date: "03/05/2025",
    description: [
      "Be a part of a team that delivers high-quality pharmaceutical solutions.",
      "Support our clients with innovative sales strategies.",
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable",
    ],
    id: 4,
    jobId: "47T89",
    location: "Boston, MA",
    salary: "$150,000 - $200,000 a year",
    schedule: "Weekends Only",
    source: "LinkedIn",
    targets: [
      {
        merits: [
          {
            fieldValues: ["field A value > 10"],
            name: "X",
          },
        ],
      },
      {
        merits: [
          {
            name: "YY",
          },
          {
            name: "YY",
          },
          {
            name: "YY",
          },
          {
            name: "YY",
          },
          {
            name: "YY",
          },
        ],
      },
    ],
    time: "10:15:00 AM",
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
    type: "Part-time",
  },
];
