// Copyright 2025 Merit International Inc. All Rights Reserved

import { useEffect } from "react";
import { useFeatureFlagsStore } from "@src/stores/featureFlagsStore";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { FeatureFlags } from "@src/configuration/featureFlags";

export const FlagEffect = () => {
  const flags = useFlags<FeatureFlags>();
  const { setFeatureFlags, setHasHydrated } = useFeatureFlagsStore(state => ({
    setFeatureFlags: state.setFeatureFlags,
    setHasHydrated: state.setHasHydrated,
  }));

  useEffect(() => {
    setFeatureFlags(flags);
    setHasHydrated(true);
  }, [flags, setFeatureFlags, setHasHydrated]);

  return null;
};
