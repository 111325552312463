// Copyright 2025 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Button, Modal, useTheme } from "@merit/frontend-components";
import { Formik } from "formik";
import { HorizontalSpacer, TemplateRules, templateRuleSchema } from "@src/components";
import { ScrollView, StyleSheet, View } from "react-native";
import { newTemplateRule } from "@src/components/TemplateRules/utils";
import { useMemo, useRef } from "react";
import type { FormikProps } from "formik";
import type { TemplateRule } from "@src/components";
import type { ViewStyle } from "react-native";

type Props = {
  readonly onClose: () => void;
};

type FormValues = {
  readonly templateRules: readonly TemplateRule[];
};

const formSchema = Yup.object({
  ruleCombinationType: Yup.string().oneOf(["all", "any"] as const),
  templateRules: Yup.array().min(1, "At least one rule expected").of(templateRuleSchema),
});

export const AddTemplateRules = ({ onClose }: Props) => {
  const { theme } = useTheme();
  const formRef = useRef<FormikProps<FormValues>>(null);

  const styles = StyleSheet.create<{
    readonly buttonsContainer: ViewStyle;
    readonly scrollViewContainer: ViewStyle;
  }>({
    buttonsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    scrollViewContainer: {
      paddingBottom: theme.spacing.m,
    },
  });

  const initialValues = useMemo(
    () => ({
      templateRules: [newTemplateRule()],
    }),
    []
  );

  return (
    <Modal maxHeight={600} onClose={onClose} width={1000}>
      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          onReset={(_, helpers) => {
            helpers.resetForm({ values: initialValues });
          }}
          onSubmit={values => {
            // eslint-disable-next-line no-console
            console.log(values);
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          validationSchema={formSchema}
        >
          <TemplateRules title="Members will see this opportunity if…" />
        </Formik>
      </ScrollView>
      <View style={styles.buttonsContainer}>
        <Button onPress={onClose} text="Cancel" type="secondary" />
        <HorizontalSpacer size={theme.spacing.s} />
        <Button
          onPress={() => {
            formRef.current?.handleSubmit();
          }}
          text="Add"
          type="primary"
        />
      </View>
    </Modal>
  );
};
