// Copyright 2024 Merit International Inc. All Rights Reserved

import { OrgAuthLandingScreen } from "./OrgAuthLanding";
import { useLogin } from "../hooks/auth";

export const LandingScreen = () => {
  const { doLoginFlow: promptLogin } = useLogin();

  return OrgAuthLandingScreen(promptLogin);
};
