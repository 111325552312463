// Copyright 2023 Merit International Inc. All Rights Reserved

import {
  Body,
  Button,
  Checkbox,
  Heading,
  Icon,
  Select,
  TextInput,
  useTheme,
} from "@merit/frontend-components";
import { ConfirmationModal } from "../../../components/Modals";
import { CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum } from "../../../gen/org-portal";
import {
  DELIMITER_OPTIONS,
  GetDatasourceColumnTypes,
  INTEGRATION_TYPE_OPTIONS,
} from "../DatasourceColumnTypes";
import { FullScreenModalLayout } from "../../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { Pressable, ScrollView, View } from "react-native";
import { Spin } from "../../../components";
import { useAlertStore } from "../../../stores/alertStore";
import { useApi } from "../../../api/api";
import { useDatasourcesItemData } from "../useDatasourcesItemData";
import { useDatasourcesMutation } from "../useDatasourceMutation";
import { useDatasourcesUpdateScreenStyles } from "./styles";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/core";
import { useRoute } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import type {
  CreateDatasourceRequestSchemaColumnsInner,
  UpdateDatasourceRequest,
} from "../../../gen/org-portal";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../../Router";
import type { RouteProp } from "@react-navigation/native";
import type { SelectProps } from "@merit/frontend-components";

type Navigation = NativeStackNavigationProp<RouteParams, "UpdateDatasource">;
type DataType = CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum;

const { Some } = Helpers;
const SCREEN_NAME = "DatasourceUpdate";

type Payload = Omit<Required<UpdateDatasourceRequest>, "schemaColumns"> & {
  readonly schemaColumns: readonly SchemaColumn[];
  readonly schemaDelimiter: string | undefined;
};

type SchemaColumn = Omit<CreateDatasourceRequestSchemaColumnsInner, "columnDataType" | "id"> & {
  readonly columnDataType: string;
  readonly key: string;
  readonly isExisting?: true;
  readonly usedAsDeletion: boolean;
};

const defaultPayload: Required<Payload> = {
  description: "",
  name: "",
  schemaColumns: [],
  schemaDelimiter: undefined,
};

const testProps = { elementName: SCREEN_NAME, screenName: SCREEN_NAME };

export const DatasourcesUpdateScreen = () => {
  const { theme } = useTheme();
  const route = useRoute<RouteProp<RouteParams, "UpdateDatasource">>();
  const navigation = useNavigation<Navigation>();
  const auth = useLoggedInAuthState();
  const { api } = useApi();
  const alert = useAlertStore();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const styles = useDatasourcesUpdateScreenStyles();
  const datasourceMutation = useDatasourcesMutation(api, auth.selectedOrgId);
  const datasourceItem = useDatasourcesItemData(api, auth.selectedOrgId, route.params.id);
  const [payload, setPayload] = useState(defaultPayload);
  const [initialpayload, setInitialPayload] = useState(defaultPayload);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const uniqueIdentifiers = payload.schemaColumns.filter(
    column => column.isIdentifier && column.columnName !== ""
  );

  const updatePayload = (data: Partial<Payload>) => {
    setPayload({
      ...payload,
      ...data,
    });
  };

  const updateSchemaColumn = (index: number, data: Partial<SchemaColumn>) => {
    const columns = payload.schemaColumns;

    updatePayload({
      schemaColumns: [
        ...columns.slice(0, index),
        {
          ...columns[index],
          ...data,
        },
        ...columns.slice(index + 1),
      ],
    });
  };

  const addSchemaColumn = () => {
    updatePayload({
      schemaColumns: [
        ...payload.schemaColumns,
        {
          columnDataType: "",
          columnName: "",
          columnPhoneNumberDefaultCountryCode: "US",
          isIdentifier: false,
          key: uuidv4(),
          usedAsDeletion: false,
        },
      ],
    });
  };

  const removeSchemaColumn = (index: number) => {
    const columns = payload.schemaColumns;

    updatePayload({
      schemaColumns: [...columns.slice(0, index), ...columns.slice(index + 1)],
    });
  };

  const computeDataFormatSelectProps = (
    column: SchemaColumn,
    index: number
  ): Pick<SelectProps, "defaultValue" | "disabled" | "onSelectOption" | "options"> => {
    switch (column.columnDataType) {
      case "PhoneNumber":
        return {
          defaultValue: { label: "US", value: "US" },
          disabled: column.isExisting,
          onSelectOption: item => {
            updateSchemaColumn(index, {
              columnPhoneNumberDefaultCountryCode: `${item.value}`,
            });
          },
          options: [{ label: "US", value: "US" }],
        };

      case "Date":
        return {
          disabled: column.isExisting,
          onSelectOption: item => {
            updateSchemaColumn(index, {
              columnDateFormat: `${item.value}`,
            });
          },
          options: [
            { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
            { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
          ],
        };

      case "DateTime":
        return {
          disabled: column.isExisting,
          onSelectOption: item => {
            updateSchemaColumn(index, {
              columnDateFormat: `${item.value}`,
            });
          },
          options: [
            { label: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
            { label: "MM-DD-YYYY HH:mm:ss", value: "MM-DD-YYYY HH:mm:ss" },
          ],
        };

      default:
        return {
          disabled: true,
          options: [],
        };
    }
  };

  const cancelEdit = () => {
    if (Object.entries(payload).toString() !== Object.entries(initialpayload).toString()) {
      setShowConfirmationModal(true);

      return;
    }

    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Datasources");
  };

  const getSchemaColumns = (schemaColumns: readonly SchemaColumn[]) =>
    schemaColumns
      .filter((column: SchemaColumn) => column.isExisting !== true)
      .map((column: SchemaColumn) => ({
        columnDataType: column.columnDataType as DataType,
        columnDateFormat: column.columnDateFormat,
        columnName: column.columnName,
        columnPhoneNumberDefaultCountryCode: column.columnPhoneNumberDefaultCountryCode,
        isIdentifier: column.isIdentifier,
        usedAsDeletion: column.usedAsDeletion,
      }));

  const submitPayload = async () => {
    setIsConfirmationModalOpen(false);
    await datasourceMutation.update(
      route.params.id,
      {
        ...payload,
        schemaColumns: getSchemaColumns(payload.schemaColumns),
      },
      () => {
        navigation.navigate("Datasources");
      }
    );
  };

  const confirmUpdate = async () => {
    try {
      await datasourceMutation.checkForErrors({
        ...payload,
        schemaColumns: getSchemaColumns(payload.schemaColumns),
      });
      setIsConfirmationModalOpen(true);
    } catch (error) {
      if (error instanceof Error) {
        alert.setAlert({
          id: uuidv4(),
          onPressDelete: () => undefined,
          text: error.message,
          type: "error",
        });
      }
    }
  };

  const getDelimiterReadableValue = (symbol: string | undefined) => {
    if (symbol === ",") {
      return "comma";
    }

    if (symbol === "|") {
      return "pipe";
    }

    if (symbol === "\t") {
      return "tab";
    }

    return undefined;
  };

  useEffect(() => {
    if (datasourceItem.data === undefined) {
      return;
    }

    const datasourceData: Payload = {
      description: datasourceItem.data.description ?? "",
      name: datasourceItem.data.name,
      schemaColumns: datasourceItem.data.columns.map(column => ({
        columnDataType: column.dataType.dataType ?? "",
        columnDateFormat: column.dataType.format ?? "",
        columnName: column.name,
        isExisting: true,
        isIdentifier: column.identifier ?? false,
        key: uuidv4(),
        usedAsDeletion: column.usedAsDeletion,
      })),
      schemaDelimiter: getDelimiterReadableValue(datasourceItem.data.schema?.delimiter),
    };

    setPayload(datasourceData);

    setInitialPayload(datasourceData);
  }, [datasourceItem.data]);

  const datasourceColumnTypes = GetDatasourceColumnTypes(false);
  const usedAsDeletionColumnTypes = GetDatasourceColumnTypes(true);

  const datasourceHasRemovalColumn =
    !datasourceItem.loading && datasourceItem.data?.columns.find(col => col.usedAsDeletion);
  const payloadHasRemovalColumn = payload.schemaColumns.find(col => col.usedAsDeletion);

  return (
    <FullScreenModalLayout onClose={cancelEdit} testProps={testProps} title="Update data source">
      <View style={styles.container}>
        <ScrollView>
          <Spin spinning={datasourceItem.loading || datasourceMutation.loading}>
            <View style={styles.content}>
              <Heading
                bold
                level="3"
                testProps={{
                  elementName: Some(testProps) ? `${testProps.elementName}Heading` : "",
                  screenName: SCREEN_NAME,
                }}
              >
                Data source name and type
              </Heading>
              <VerticalSpacer size={theme.spacing.xxl} />
              <TextInput
                label="Name *"
                onChangeText={value => {
                  updatePayload({ name: value });
                }}
                placeholder="Name"
                showErrorIcon={datasourceMutation.getValidationError("name")}
                testProps={{
                  elementId: datasourceItem.data?.id,
                  elementName: Some(testProps)
                    ? `${testProps.elementName}NameTextInput`
                    : "NameTextInput",
                  screenName: SCREEN_NAME,
                }}
                value={payload.name}
              />

              <VerticalSpacer size={theme.spacing.xxl} />

              <TextInput
                label="Description"
                numberOfLines={4}
                onChangeText={value => {
                  updatePayload({ description: value });
                }}
                placeholder="Description"
                testProps={{
                  elementId: datasourceItem.data?.id,
                  elementName: Some(testProps)
                    ? `${testProps.elementName}DescriptionTextInput`
                    : "DescriptionTextInput",
                  screenName: SCREEN_NAME,
                }}
                value={payload.description}
              />
              <VerticalSpacer size={theme.spacing.s} />
              <Body testProps={testProps}>300 maximum character limit</Body>
              <VerticalSpacer size={theme.spacing.xxl} />

              <View
                style={{
                  alignItems: "flex-start",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View style={{ flex: 1 }}>
                  <Select
                    defaultValue={INTEGRATION_TYPE_OPTIONS.find(item => item.value === "CSV")}
                    disabled
                    label="Integration type *"
                    options={INTEGRATION_TYPE_OPTIONS}
                    testProps={{
                      elementId: datasourceItem.data?.id,
                      elementName: Some(testProps)
                        ? `${testProps.elementName}IntegrationTypeSelect`
                        : "IntegrationTypeSelect",
                      screenName: SCREEN_NAME,
                    }}
                  />
                </View>
                <HorizontalSpacer size={theme.spacing.xxl} />
                <View style={{ flex: 1 }}>
                  <TextInput
                    disabled
                    label="Preset adapter type"
                    testProps={{
                      elementId: datasourceItem.data?.id,
                      elementName: Some(testProps)
                        ? `${testProps.elementName}AdapterTypeTextInput`
                        : "AdapterTypeTextInput",
                      screenName: SCREEN_NAME,
                    }}
                    value="CSV and other flat files"
                  />
                  <VerticalSpacer size={theme.spacing.s} />
                  <Body testProps={testProps}>Can support CSV, TSV, and TXT files</Body>
                </View>
              </View>

              <View style={styles.separator} />

              <Heading bold level="3">
                Schema
              </Heading>
              <VerticalSpacer size={theme.spacing.xxl} />
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: 200 }}>
                    <Select
                      defaultValue={DELIMITER_OPTIONS.find(
                        item =>
                          item.value ===
                          getDelimiterReadableValue(datasourceItem.data?.schema?.delimiter)
                      )}
                      disabled
                      key={datasourceItem.data?.schema?.delimiter}
                      label="Delimiter"
                      options={DELIMITER_OPTIONS}
                      testProps={{
                        elementId: datasourceItem.data?.id,
                        elementName: Some(testProps)
                          ? `${testProps.elementName}DelimiterTypeSelect`
                          : "DelimiterTypeSelect",
                        screenName: SCREEN_NAME,
                      }}
                      usePortal
                    />
                  </View>

                  <HorizontalSpacer size={theme.spacing.xxl} />

                  <View>
                    <Body
                      testProps={{
                        elementId: datasourceItem.data?.id,
                        elementName: Some(testProps)
                          ? `${testProps.elementName}IdColumnLabel`
                          : "IdColumnLabel",
                        screenName: SCREEN_NAME,
                      }}
                    >
                      Unique identifier(s)
                    </Body>
                    <VerticalSpacer size={theme.spacing.xs} />
                    <View style={styles.tagContainer}>
                      {uniqueIdentifiers.length === 0 && <Body>No identifier selected</Body>}
                      {uniqueIdentifiers.map(item => (
                        <View key={item.key} style={styles.tag}>
                          <Body
                            testProps={{
                              elementName: Some(testProps)
                                ? `${testProps.elementName}IdColumnValue`
                                : "IdColumnValue",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {item.columnName}
                          </Body>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>

                <View style={{ alignSelf: "flex-end" }}>
                  <Button
                    iconLeft="addSmallDefault"
                    onPress={() => {
                      addSchemaColumn();
                    }}
                    size="small"
                    testProps={{
                      elementId: datasourceItem.data?.id,
                      elementName: Some(testProps)
                        ? `${testProps.elementName}AddColumnTopButton`
                        : "AddColumnTopButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Add column"
                    type="secondary"
                  />
                </View>
              </View>

              <VerticalSpacer size={theme.spacing.xxl} />

              <View style={{ backgroundColor: theme.colors.background.white }}>
                <View
                  style={{
                    borderBottomColor: theme.colors.border.default,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingHorizontal: 32,
                  }}
                >
                  <View style={{ flex: 2, padding: theme.spacing.l }}>
                    <Body style={{ fontWeight: "bold" }} testProps={testProps}>
                      Column name
                    </Body>
                  </View>
                  <View style={{ flex: 1, padding: theme.spacing.l }}>
                    <Body style={{ fontWeight: "bold" }} testProps={testProps}>
                      Data type
                    </Body>
                  </View>
                  <View style={{ flex: 1, padding: theme.spacing.l }}>
                    <Body style={{ fontWeight: "bold" }} testProps={testProps}>
                      Format
                    </Body>
                  </View>
                  <View style={{ flex: 1, padding: theme.spacing.l }}>
                    <Body style={{ fontWeight: "bold" }} testProps={testProps}>
                      Unique identifier
                    </Body>
                  </View>
                  <View style={{ flex: 1, padding: theme.spacing.l }}>
                    <Body style={{ fontWeight: "bold" }} testProps={testProps}>
                      Removal
                    </Body>
                  </View>
                  <View style={{ padding: theme.spacing.l, width: 52 }} />
                </View>

                {payload.schemaColumns.map((column: SchemaColumn, index) => (
                  <View
                    key={column.key}
                    style={{
                      borderBottomColor: theme.colors.border.default,
                      borderBottomWidth: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingHorizontal: 32,
                    }}
                  >
                    <View style={{ flex: 2, justifyContent: "center", padding: theme.spacing.l }}>
                      <TextInput
                        autoFocus
                        disabled={column.isExisting}
                        maxLength={120}
                        onChangeText={value => {
                          updateSchemaColumn(index, { columnName: value });
                        }}
                        placeholder="Name"
                        showErrorIcon={datasourceMutation.getValidationError(
                          `schemaColumns[${index}].columnName`
                        )}
                        testProps={{
                          elementId: datasourceItem.data?.id,
                          elementName: Some(testProps)
                            ? `${testProps.elementName}ColumnNameTextInput-${index}`
                            : `ColumnNameTextInput-${index}`,
                          screenName: SCREEN_NAME,
                        }}
                        value={column.columnName}
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", padding: theme.spacing.l }}>
                      <Select
                        defaultValue={
                          datasourceColumnTypes.find(
                            item => item.value === column.columnDataType
                          ) ?? {
                            label: "Data type",
                            value: "",
                          }
                        }
                        disabled={column.isExisting}
                        label="Data type"
                        onSelectOption={item => {
                          updateSchemaColumn(index, {
                            columnDataType: item.value as DataType,
                            columnDateFormat: undefined,
                          });
                        }}
                        options={
                          column.usedAsDeletion ? usedAsDeletionColumnTypes : datasourceColumnTypes
                        }
                        showLabel={false}
                        testProps={{
                          elementId: datasourceItem.data?.id,
                          elementName: Some(testProps)
                            ? `${testProps.elementName}DataTypeSelect-${index}`
                            : `DataTypeSelect-${index}`,
                          screenName: SCREEN_NAME,
                        }}
                        usePortal
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", padding: theme.spacing.l }}>
                      <Select
                        defaultValue={
                          ["Date", "DateTime"].includes(column.columnDataType)
                            ? {
                                label: column.columnDateFormat ?? "Format",
                                value: column.columnDateFormat ?? "",
                              }
                            : { label: "Format", value: "" }
                        }
                        key={`${column.columnDataType}${column.key}`}
                        label="Format"
                        showLabel={false}
                        testProps={{
                          elementId: datasourceItem.data?.id,
                          elementName: Some(testProps)
                            ? `${testProps.elementName}DateFormatSelect-${index}`
                            : `DateFormatSelect-${index}`,
                          screenName: SCREEN_NAME,
                        }}
                        usePortal
                        {...computeDataFormatSelectProps(column, index)}
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", padding: theme.spacing.l }}>
                      <Checkbox
                        defaultChecked={column.isIdentifier}
                        disabled
                        onChange={value => {
                          updateSchemaColumn(index, { isIdentifier: value });
                        }}
                        testProps={{
                          elementId: datasourceItem.data?.id,
                          elementName: Some(testProps)
                            ? `${testProps.elementName}UniqueIdentifierCheckBox-${index}`
                            : `UniqueIdentifierCheckBox-${index}`,
                          screenName: SCREEN_NAME,
                        }}
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", padding: theme.spacing.l }}>
                      <Checkbox
                        defaultChecked={column.usedAsDeletion}
                        disabled={
                          Some(datasourceHasRemovalColumn) ||
                          column.isExisting === true ||
                          column.columnDataType !==
                            CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Bool ||
                          (Some(payloadHasRemovalColumn) &&
                            payloadHasRemovalColumn.columnName !== column.columnName)
                        }
                        onChange={value => {
                          updateSchemaColumn(index, {
                            usedAsDeletion: value,
                          });
                        }}
                        testProps={{
                          elementId: datasourceItem.data?.id,
                          elementName: Some(testProps)
                            ? `${testProps.elementName}RemovalCheckBox-${index}`
                            : `RemovalCheckBox-${index}`,
                          screenName: SCREEN_NAME,
                        }}
                      />
                    </View>
                    <View style={{ justifyContent: "center", padding: theme.spacing.l, width: 52 }}>
                      {column.isExisting !== true && (
                        <Pressable
                          onPress={() => {
                            removeSchemaColumn(index);
                          }}
                        >
                          <Icon
                            name="closeSmallDefault"
                            testProps={{
                              elementId: datasourceItem.data?.id,
                              elementName: Some(testProps)
                                ? `${testProps.elementName}RemoveSchemaColumnIcon-${index}`
                                : `RemoveSchemaColumnIcon-${index}`,
                              screenName: SCREEN_NAME,
                            }}
                          />
                        </Pressable>
                      )}
                    </View>
                  </View>
                ))}
              </View>

              <VerticalSpacer size={theme.spacing.xxl} />

              <View style={{ alignItems: "flex-start" }}>
                <Button
                  iconLeft="addSmallDefault"
                  onPress={() => {
                    addSchemaColumn();
                  }}
                  size="small"
                  testProps={{
                    elementId: datasourceItem.data?.id,
                    elementName: Some(testProps)
                      ? `${testProps.elementName}AddColumnBottomButton`
                      : "AddColumnBottomButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Add column"
                  type="secondary"
                />
              </View>
            </View>
          </Spin>
        </ScrollView>

        <View style={styles.footer}>
          <View style={{ marginLeft: theme.spacing.l }}>
            <Button
              onPress={cancelEdit}
              testProps={{
                elementId: datasourceItem.data?.id,
                elementName: Some(testProps)
                  ? `${testProps.elementName}CancelButton`
                  : "CancelButton",
                screenName: SCREEN_NAME,
              }}
              text="Cancel"
              type="secondary"
            />
          </View>
          <View style={{ marginLeft: theme.spacing.l }}>
            <Button
              onPress={() => {
                confirmUpdate();
              }}
              testProps={{
                elementId: datasourceItem.data?.id,
                elementName: Some(testProps)
                  ? `${testProps.elementName}UpdateButton`
                  : "UpdateButton",
                screenName: SCREEN_NAME,
              }}
              text="Save"
            />
          </View>
        </View>
        {isConfirmationModalOpen && (
          <ConfirmationModal
            onClose={() => {
              setIsConfirmationModalOpen(false);
            }}
            onOk={() => {
              submitPayload();
            }}
            testProps={testProps}
            text="Are you sure you want to save? Saving this change will impact any org that has extended
          this data source from you."
            title="Save data source"
          />
        )}
      </View>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onOk={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();

              return;
            }

            navigation.navigate("Datasources");
            setShowConfirmationModal(false);
          }}
          testProps={testProps}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </FullScreenModalLayout>
  );
};
