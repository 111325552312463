// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Icon } from "@merit/frontend-components";
import { ConfirmationModal } from "../../../components/Modals";
import { DetailsDrawer } from "../../../layouts/DetailsDrawer";
import { Errors } from "./Errors";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../../components/Spacer";
import { Overview } from "./Overview";
import { Schema } from "./Schema";
import { ScrollView, View } from "react-native";
import { Spin, Tabs } from "../../../components";
import { useAlertStore } from "../../../stores";
import { useApi } from "../../../api/api";
import { useDatasourceDetailsScreenStyles } from "./styles";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import type { GetDatasource200Response } from "../../../gen/org-portal";
import type { OPTestProps } from "../../../types/TestProps";
import type { ReactNode } from "react";

const { None } = Helpers;

type Tab = "errors" | "overview" | "schema";
type Options<T> = { readonly key: T; readonly label: ReactNode | string };

const generateTabs = (params: {
  readonly isError: boolean;
  readonly testProps?: OPTestProps;
}): readonly Options<Tab>[] => [
  { key: "overview", label: "Overview" },
  { key: "schema", label: "Schema" },
  {
    key: "errors",
    label: (
      <View style={{ flexDirection: "row" }}>
        <Body testProps={params.testProps}>Most recent error</Body>
        {params.isError && (
          <>
            <HorizontalSpacer size={8} />
            <Icon name="warningMediumCritical" style={{ height: 16, width: 16 }} />
          </>
        )}
      </View>
    ),
  },
];

type Props = {
  readonly onDrawerClose: (shouldRefresh?: boolean) => void;
  readonly correlationId?: string;
  readonly datasourceId?: string;
  readonly onPressEdit: () => void;
  readonly onPressUpload: () => void;
  readonly testProps: OPTestProps;
};

const { Some } = Helpers;
const SCREEN_NAME = "DatasourceDetails";

export const DatasourceDetails = ({
  correlationId,
  datasourceId,
  onDrawerClose,
  onPressEdit,
  onPressUpload,
  testProps,
}: Props) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const [selectedTab, setSelectedTab] = useState<Tab>("overview");
  const [datasource, setDatasource] = useState<GetDatasource200Response>();
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useServerErrorHandler();
  const styles = useDatasourceDetailsScreenStyles();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<ReactNode>();
  const { deleteAlert, setAlert } = useAlertStore();
  const { configuration } = useLoadedConfigurationState();

  const downloadErrors = async () => {
    if (None(datasource)) {
      throw new Error("Somehow data source not found");
    }

    const { datasource: dataSourceDetails } = datasource;
    try {
      const data = await api.getDatasourceErrors({
        datasourceID: dataSourceDetails.id,
        orgID: selectedOrgId,
        start: datasource.ingestionLog.createdAt,
      });

      const objectURL = URL.createObjectURL(data);
      ref.current?.setAttribute("download", `${dataSourceDetails.name}-errors.csv`);
      ref.current?.setAttribute("href", objectURL);
      ref.current?.click();

      URL.revokeObjectURL(objectURL);
    } catch (error) {
      await errorHandler(error);
    }
  };

  useEffect(() => {
    const getDatasourceDetails = async () => {
      if (Some(selectedOrgId) && Some(datasourceId)) {
        try {
          setIsLoading(true);
          const response = await api.getDatasource({
            datasourceID: datasourceId,
            errorStart: datasource?.lastError,
            mappedTemplatesLimit: 100,
            orgID: selectedOrgId,
          });
          setDatasource(response);
        } catch (error) {
          errorHandler(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    getDatasourceDetails();
  }, [api, datasource?.lastError, datasourceId, errorHandler, selectedOrgId]);

  const deleteDatasource = async () => {
    const dataSourceID = datasource?.datasource.id;
    if (None(dataSourceID)) {
      return;
    }
    try {
      setIsLoading(true);
      await api.deleteDataSource({
        datasourceID: dataSourceID,
        orgID: selectedOrgId,
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "Data source has been deleted.",
        type: "success",
      });
      setDatasource(undefined);
      onDrawerClose(true);
    } catch (error) {
      await errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConfirmationModal = () => {
    setConfirmationModal(
      <ConfirmationModal
        buttonText="ok"
        onClose={() => {
          setConfirmationModal(undefined);
        }}
        onOk={() => {
          setConfirmationModal(undefined);
          deleteDatasource();
        }}
        testProps={{
          elementName: "deleteDataSourceConfirmationModal",
          screenName: SCREEN_NAME,
        }}
        text="All containers with data populating from this data source will continue show the last uploaded values."
        title="Are you sure you want to delete this data source? "
      />
    );
  };

  const hideDataSourceToggle = async (isHidden: boolean) => {
    if (None(datasourceId)) {
      return;
    }
    try {
      await api.datasourceVisibility({
        datasourceID: datasourceId,
        orgID: selectedOrgId,
        properties: { hide: isHidden },
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: isHidden ? "Data source has been hidden." : "Data source has been unhidden.",
        type: "success",
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setDatasource(undefined);
      onDrawerClose(true);
    }
  };

  if (isLoading) {
    return (
      <View
        style={[
          styles.container,
          { alignItems: "center", flexDirection: "column", justifyContent: "center" },
        ]}
      >
        <Spin />
      </View>
    );
  }

  if (datasource === undefined) {
    return (
      <View
        style={[
          styles.container,
          { alignItems: "center", flexDirection: "column", justifyContent: "center" },
        ]}
      >
        <Body
          testProps={{
            elementId: datasourceId,
            elementName: Some(testProps) ? `${testProps.elementName}Body` : "",
            screenName: SCREEN_NAME,
          }}
        >
          No data available
        </Body>
      </View>
    );
  }

  return (
    <>
      <DetailsDrawer
        actionButtons={
          <View style={styles.buttonsContainer}>
            <Button
              onPress={onPressEdit}
              size="small"
              testProps={{
                elementId: datasourceId,
                elementName: "editButton",
                screenName: SCREEN_NAME,
              }}
              text="Edit"
              type="secondary"
            />
            <HorizontalSpacer />
            <Button
              onPress={onPressUpload}
              size="small"
              testProps={{
                elementId: datasourceId,
                elementName: "uploadFileButton",
                screenName: SCREEN_NAME,
              }}
              text="Upload file"
              type="secondary"
            />
            <HorizontalSpacer />
            <Button
              disabled={datasource.ingestionLog.createdAt === ""}
              onPress={downloadErrors}
              size="small"
              testProps={{
                elementId: datasourceId,
                elementName: "downloadErrorsButton",
                screenName: SCREEN_NAME,
              }}
              text="Download errors"
              type="secondary"
            />
            <>
              <HorizontalSpacer />
              <Button
                onPress={() => {
                  const isHidden = datasource.datasource.isHidden ?? false;
                  hideDataSourceToggle(!isHidden);
                }}
                size="small"
                testProps={{
                  elementId: datasourceId,
                  elementName: "hideUnHideButton",
                  screenName: SCREEN_NAME,
                }}
                text={
                  Some(datasource.datasource.isHidden) && datasource.datasource.isHidden
                    ? "Unhide"
                    : "Hide"
                }
                type="secondary"
              />
            </>
            <HorizontalSpacer />
            {selectedOrgId !== configuration.solUUID && (
              <>
                {datasource.mappedTemplates.length <= 0 && (
                  <Button
                    onPress={deleteConfirmationModal}
                    size="small"
                    testProps={{
                      elementId: datasourceId,
                      elementName: "deleteButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Delete"
                    type="destructive"
                  />
                )}
              </>
            )}

            <a ref={ref} style={{ display: "none" }} />
          </View>
        }
        onPressClose={() => {
          setIsLoading(true);
          setDatasource(undefined);
          setSelectedTab("overview");
          onDrawerClose();
        }}
        subTitle={`Data source ID: ${datasource.datasource.id}`}
        testProps={{
          elementId: datasource.datasource.id,
          elementName: "datasourceDetailsDrawer",
          screenName: SCREEN_NAME,
        }}
        title={datasource.datasource.name}
      >
        <View style={styles.tabContainer}>
          <Tabs
            items={generateTabs({
              isError:
                datasource.datasource.lastError !== "" &&
                datasource.datasource.lastError !== undefined,
              testProps: {
                elementId: datasource.datasource.id,
                elementName: "TabsErrorsTab",
                screenName: SCREEN_NAME,
              },
            })}
            onChange={item => {
              setSelectedTab(item);
            }}
            selected={selectedTab}
            testProps={{
              elementId: datasourceId,
              elementName: Some(testProps) ? `${testProps.elementName}Tabs` : "",
              screenName: SCREEN_NAME,
            }}
          />
        </View>

        <ScrollView>
          {selectedTab === "overview" && (
            <View style={{ paddingHorizontal: 32 }}>
              <Overview
                datasource={datasource}
                testProps={{
                  elementId: datasourceId,
                  elementName: Some(testProps)
                    ? `${testProps.elementName}OverviewTab`
                    : "OverviewTab",
                  screenName: SCREEN_NAME,
                }}
              />
            </View>
          )}

          {selectedTab === "schema" && (
            <Schema
              datasourceSchema={datasource.datasource.columns}
              testProps={{
                elementId: datasourceId,
                elementName: Some(testProps) ? `${testProps.elementName}SchemaTab` : "SchemaTab",
                screenName: SCREEN_NAME,
              }}
            />
          )}

          {selectedTab === "errors" && (
            <Errors
              correlationId={correlationId}
              error={datasource.datasource.lastError}
              testProps={{
                elementId: datasourceId,
                elementName: Some(testProps) ? `${testProps.elementName}ErrorsTab` : "ErrorsTab",
                screenName: SCREEN_NAME,
              }}
            />
          )}
        </ScrollView>
      </DetailsDrawer>
      {confirmationModal}
    </>
  );
};
