// Copyright 2025 Merit International Inc. All Rights Reserved

import { useFeatureFlagsStore } from "@src/stores/featureFlagsStore";
import type { FeatureFlags } from "@src/configuration/featureFlags";

type UseFeatureFlags = () => FeatureFlags;

export const useFeatureFlags: UseFeatureFlags = () => {
  const featureFlags = useFeatureFlagsStore(state => state.featureFlags);

  return featureFlags;
};
