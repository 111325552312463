// Copyright 2025 Merit International Inc. All Rights Reserved

import { InvalidPolicyError } from "@src/screens/Policies/utils";
import { createFilter } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import type { FieldRule, Option, TemplateRule } from "./types";

export const newFieldRule = (): FieldRule => ({
  id: uuidv4(),
});

export const newTemplateRule = (): TemplateRule => ({
  fieldCombinationType: "all",
  fieldRules: [],
  fieldValuesType: "any",
  id: uuidv4(),
  templateId: null,
  templateStatus: "active",
});

export const SplitTime = (arg: string): readonly string[] => {
  const splitHMS = arg.split(":");
  if (splitHMS.length !== 3) {
    throw new InvalidPolicyError("Wanted HH:MM:SS format for time field");
  }

  return splitHMS;
};

export const onLabel = createFilter({
  matchFrom: "any",
  stringify: opt => opt.label,
});

export const findOptionByValue = (options: readonly Option[], value: string) =>
  options.find(option => option.value === value);
