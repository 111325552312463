// Copyright 2024 Merit International Inc. All Rights Reserved.

import { DefaultLayout } from "@src/layouts";
import { DetailsDrawer } from "@src/layouts/DetailsDrawer";
import { FullScreenModalLayout } from "@src/layouts/FullScreenModalLayout";
import { PreLoginLayout } from "@src/layouts/PreLoginLayout";

type Layouts = {
  readonly DefaultLayout: typeof DefaultLayout;
  readonly FullScreenModalLayout: typeof FullScreenModalLayout;
  readonly PreLoginLayout: typeof PreLoginLayout;
  readonly DetailsDrawer: typeof DetailsDrawer;
};

export const useFlaggedLayout = (): Layouts => ({
  DefaultLayout,
  DetailsDrawer,
  FullScreenModalLayout,
  PreLoginLayout,
});
