// Copyright 2025 Merit International Inc. All Rights Reserved

import { initialize as initializeLaunchDarkly } from "./LaunchDarkly";
import { initialize as initializeNone } from "./None";
import { initialize as initializeUnleash } from "./Unleash";
import { useAuthStore, useConfigurationStore } from "@src/stores";
import { useEffect, useState } from "react";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "@src/configuration";
import type { ProviderFunctionalComponent, UseInitializeFeatureFlagProvider } from "./types";

const envConfig = Constants.manifest?.extra as EnvConfig;

export const useInitializeFeatureFlagProvider: UseInitializeFeatureFlagProvider = () => {
  const configuration = useConfigurationStore(state => state.configuration);
  const { profile, selectedOrgId } = useAuthStore();

  const [Provider, setProvider] = useState<ProviderFunctionalComponent>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      if (configuration === undefined) {
        return;
      }

      // dear developer: ensure setting state is in the form setState(prevState => Component), otherwise you'll encounter a hard to debug bug
      switch (envConfig.featureFlags.provider) {
        case "LaunchDarkly":
          const LDProvider = await initializeLaunchDarkly({
            entityID: profile?.entityID,
            launchDarklyClientID: configuration.launchDarklyClientID,
            selectedOrgId,
          });
          setProvider(() => LDProvider);
          break;
        case "Unleash":
          const UnleashProvider = await initializeUnleash({});
          setProvider(() => UnleashProvider);
          break;
        case "None":
          const NoneProvider = await initializeNone({});
          setProvider(() => NoneProvider);
          break;
        default:
      }
    })();
  }, [configuration, profile?.entityID, selectedOrgId]);

  useEffect(() => {
    if (Provider !== undefined) {
      setIsInitialized(true);
    }
  }, [Provider]);

  return { FeatureFlagProvider: Provider, isInitialized };
};
