// Copyright 2024 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { PolicyForm } from "./PolicyForm";
import { Spin, templateRuleSchema } from "@src/components";
import { View } from "react-native";
import { apiPolicyToFormPolicy } from "@src/screens/Policies/utils";
import { usePolicy } from "../hooks";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { FC } from "react";
import type { Policy } from "@src/gen/org-portal";
import type { PolicyValues } from "../types";

type PolicyFormikWrapperProps = {
  readonly policyId?: Policy["id"];
  readonly onSubmit: (values: PolicyValues) => void;
};

const formSchema = Yup.object({
  policyDescription: Yup.string()
    .optional()
    .max(300, "Policy description must be 300 characters or less"),
  policyName: Yup.string()
    .min(3, "Policy name must be at least 3 characters")
    .required("Policy name is a required field"),
  policyPermissions: Yup.object().shape({
    othersUse: Yup.string().oneOf(["yes", "no"] as const),
    othersView: Yup.string().oneOf(["yes", "no"] as const),
  }),
  ruleCombinationType: Yup.string().oneOf(["all", "any"] as const),
  templateRules: Yup.array().min(1, "At least one rule expected").of(templateRuleSchema),
});

const { None } = Helpers;

export const PolicyFormikWrapper: FC<PolicyFormikWrapperProps> = ({ onSubmit, policyId }) => {
  const ApiPolicyValues = usePolicy(policyId);
  const { theme } = useTheme();

  if (Boolean(policyId) && None(ApiPolicyValues)) {
    return (
      <View
        style={{
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  const initialValues: PolicyValues =
    ApiPolicyValues === undefined
      ? {
          policyName: "",
          policyPermissions: {
            othersUse: "yes",
            othersView: "yes",
          },
          ruleCombinationType: "all",
          templateRules: [],
        }
      : apiPolicyToFormPolicy(ApiPolicyValues);

  return (
    <Formik<PolicyValues>
      initialValues={initialValues}
      onReset={(values, helpers) => {
        helpers.resetForm({ values: initialValues });
      }}
      onSubmit={values => {
        onSubmit(values);
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={formSchema}
    >
      <PolicyForm policyId={policyId} />
    </Formik>
  );
};
