// Copyright 2025 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import { useInitializeBaseFieldIds } from "./useInitializeStoreData";
import { useInitializeConfiguration } from "./useInitializeConfiguration";
import { useInitializeDatadog } from "./useInitializeDatadog";
import { useInitializeFeatureFlagProvider } from "./useInitializeFeatureFlagProvider";
import { useInitializeFonts } from "./useInitializeFonts";

export const useInitializeApp = () => {
  const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);

  const { isInitialized: isConfigurationInitialized } = useInitializeConfiguration();
  const _ = useInitializeBaseFieldIds();
  const { isWorkDone: isDatadogWorkDone } = useInitializeDatadog();
  const { isInitialized: areFontsInitialized } = useInitializeFonts();
  const { FeatureFlagProvider, isInitialized: isFeatureFlagProviderInitialized } =
    useInitializeFeatureFlagProvider();

  useEffect(() => {
    if (
      !isAppLoaded &&
      areFontsInitialized &&
      isDatadogWorkDone &&
      isConfigurationInitialized &&
      isFeatureFlagProviderInitialized
    ) {
      setIsAppLoaded(true);
    }
  }, [
    areFontsInitialized,
    isAppLoaded,
    isConfigurationInitialized,
    isDatadogWorkDone,
    isFeatureFlagProviderInitialized,
  ]);

  return { FeatureFlagProvider, isAppLoaded };
};
