// Copyright 2025 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { featureFlagDefaults } from "@src/configuration/featureFlags";
import type { FeatureFlags } from "@src/configuration/featureFlags";

export type FeatureFlagsStore = {
  readonly featureFlags: FeatureFlags;
  readonly hasHydrated: boolean;
  readonly getFeatureFlag: <T extends keyof FeatureFlags>(name: T) => FeatureFlags[T];
  readonly setFeatureFlag: <T extends keyof FeatureFlags>(name: T, value: FeatureFlags[T]) => void;
  readonly setFeatureFlags: (featureFlags: FeatureFlags) => void;
  readonly setHasHydrated: (hasHydrated: boolean) => void;
};

export const useFeatureFlagsStore = create<FeatureFlagsStore>()((set, get) => ({
  featureFlags: featureFlagDefaults,
  getFeatureFlag: name => get().featureFlags[name],
  hasHydrated: false,
  setFeatureFlag: (name, value) => {
    const currentFlags = get().featureFlags;
    set(() => ({
      featureFlags: {
        ...currentFlags,
        [name]: value,
      },
    }));
  },
  setFeatureFlags: featureFlags => {
    set(() => ({ featureFlags }));
  },
  setHasHydrated: hasHydrated => {
    set(() => ({ hasHydrated }));
  },
}));
