// Copyright 2025 Merit International Inc. All Rights Reserved

import { AddTemplateRules } from "./AddTemplateRules";
import {
  Body,
  Button,
  DropdownMenu,
  Icon,
  LineSeparator,
  MenuItemKind,
  Switch,
  useTheme,
} from "@merit/frontend-components";
import { FlatList, Pressable, StyleSheet, View } from "react-native";
import { Helpers } from "@merit/frontend-utils";
import { OpportunitiesItem } from "./OpportunitiesItem";
import { OpportunitiesMockData } from "./OpportunitiesMockData";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { offset, useFloating } from "@floating-ui/react-native";
import { useAlertStore } from "@src/stores";
import { useCallback, useState } from "react";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import type { ViewStyle } from "react-native";

const { Some } = Helpers;

export const OpportunitiesScreen = () => {
  const { theme } = useTheme();
  const {
    floatingStyles,
    refs: { setFloating, setReference },
  } = useFloating({
    middleware: [offset(theme.spacing.s)],
    placement: "top-start",
  });
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);
  const [addGroupToOpportunityId, setAddGroupToOpportunityId] = useState<number>();

  const styles = StyleSheet.create<{
    readonly contentContainer: ViewStyle;
    readonly listOptionsContainer: ViewStyle;
    readonly searchContainer: ViewStyle;
    readonly searchToggleContainer: ViewStyle;
    readonly sortByDropdownButton: ViewStyle;
    readonly sortByDropdownButtonContainer: ViewStyle;
    readonly sortByDropdownMenuContainer: ViewStyle;
  }>({
    contentContainer: {
      flex: 1,
    },
    listOptionsContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
    },
    searchContainer: {
      flexDirection: "row",
    },
    searchToggleContainer: {
      flexDirection: "row",
      gap: theme.spacing.xs,
      paddingLeft: theme.spacing.s,
      paddingTop: theme.spacing.xs,
    },
    sortByDropdownButton: {
      flexDirection: "row",
      gap: theme.spacing.xs,
      padding: theme.spacing.l,
    },
    sortByDropdownButtonContainer: {},
    sortByDropdownMenuContainer: {
      ...floatingStyles,
      display: isDropdownMenuVisible ? "flex" : "none",
    },
  });

  const [isSwitchToggled, setIsSwitchToggled] = useState(false);

  const { DefaultLayout } = useFlaggedLayout();
  const { deleteAlert, setAlert } = useAlertStore();

  const showAlertNotImplemented = useCallback(() => {
    setAlert({
      id: "notImplemented",
      onPressDelete: id => {
        deleteAlert(id);
      },
      text: "NotImplemented",
      type: "error",
    });
  }, [deleteAlert, setAlert]);

  return (
    <DefaultLayout
      breadcrumbs={[{ name: "Create & Configure" }, { name: "Opportunities" }]}
      headerRightElements={
        <>
          <View style={styles.searchContainer}>
            <SearchForm onSearch={showAlertNotImplemented} />
            <View style={styles.searchToggleContainer}>
              <Body>by ID</Body>
              <Switch
                accessibilityLabel="searchBy"
                onToggle={value => {
                  setIsSwitchToggled(value);
                }}
                value={isSwitchToggled}
              />
              <Body>by Title</Body>
            </View>
          </View>
          <Button onPress={showAlertNotImplemented} size="small" text="Link w/ Platform" />
        </>
      }
      isHeaderSeparatorVisible={false}
      title="Opportunities"
    >
      <View style={styles.contentContainer}>
        <View style={styles.listOptionsContainer}>
          <View style={styles.sortByDropdownButtonContainer}>
            <Pressable
              onPress={() => {
                setIsDropdownMenuVisible(prev => !prev);
              }}
              ref={setReference}
              style={styles.sortByDropdownButton}
            >
              <Body>Sort by: Newest</Body>
              <Icon name="chevronDownSmallAction" />
            </Pressable>
            {/* TODO: fix this because its bounds go into the content */}
            <View ref={setFloating} style={styles.sortByDropdownMenuContainer}>
              <DropdownMenu
                menuItems={[
                  {
                    id: "newest",
                    menuItemKind: MenuItemKind.Pressable,
                    onPress: showAlertNotImplemented,
                    text: "Newest",
                  },
                  {
                    id: "oldest",
                    menuItemKind: MenuItemKind.Pressable,
                    onPress: showAlertNotImplemented,
                    text: "Oldest",
                  },
                ]}
              />
            </View>
          </View>
        </View>
        <LineSeparator />
        <FlatList
          ItemSeparatorComponent={LineSeparator}
          data={OpportunitiesMockData}
          keyExtractor={item => item.jobId}
          renderItem={({ item }) => (
            <OpportunitiesItem
              onPressAddGroup={() => {
                setAddGroupToOpportunityId(item.id);
              }}
              opportunity={item}
            />
          )}
        />
      </View>
      {Some(addGroupToOpportunityId) && (
        <AddTemplateRules
          onClose={() => {
            setAddGroupToOpportunityId(undefined);
          }}
        />
      )}
    </DefaultLayout>
  );
};
