// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, Switch, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { Errors, Helpers } from "@merit/frontend-utils";
import { Footer } from "./Footer";
import { Formik } from "formik";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import { Tooltip } from "../../components";
import React, { useState } from "react";
import type { FormikProps } from "formik";
import type { GetDatasource200ResponseMappedTemplatesInnerTypeEnum } from "../../gen/org-portal";

const { None } = Helpers;
const { UnreachableCaseError } = Errors;

export type FormValues = {
  readonly autoApprove: boolean;
  readonly autoRevoke: boolean;
};

type Props = {
  readonly unSaveConfirmation: () => void;
  readonly onSave: (formValues: FormValues) => void;
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
  readonly autoApprove: boolean;
  readonly autoRevoke: boolean;
  readonly type: GetDatasource200ResponseMappedTemplatesInnerTypeEnum;
};

export const Settings = ({
  autoApprove,
  autoRevoke,
  formRef,
  onSave,
  type,
  unSaveConfirmation,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    cardContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      flexDirection: "row",
      height: 72,
      justifyContent: "space-between",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xxl,
    },
  });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAutoRevokeConfirmationModalOpen, setIsAutoRevokeConfirmationModalOpen] = useState(false);
  const [isAutoRevoke, setIsAutoRevoke] = useState(autoRevoke);
  const [isAutoApprove, setIsAutoApprove] = useState(autoApprove);
  const [isAutoApproveConfirmationModalOpen, setIsAutoApproveConfirmationModalOpen] =
    useState(false);

  const getTemplateType = () => {
    switch (type) {
      case "Folio":
        return "folios";
      case "Merit":
        return "merits";
      case "Persona":
        return "personas";
      default:
        throw new UnreachableCaseError(type);
    }
  };

  return (
    <>
      <View style={{ alignItems: "center", flex: 1, paddingVertical: theme.spacing.xxl }}>
        <View style={{ width: 960 }}>
          <Heading bold level="3">
            Settings
          </Heading>
          <VerticalSpacer size={theme.spacing.s} />
          <Body>Select which settings you want to enable for this template</Body>
          <VerticalSpacer size={theme.spacing.xxl} />

          <Formik<FormValues>
            enableReinitialize
            initialValues={{ autoApprove, autoRevoke }}
            innerRef={formRef}
            onSubmit={values => {
              onSave(values);
            }}
          >
            <>
              <View style={styles.cardContainer}>
                <View style={{ alignItems: "center", flexDirection: "row" }}>
                  <Heading level="4">Auto-approve {getTemplateType()}</Heading>
                  <HorizontalSpacer size={theme.spacing.s} />
                  <Tooltip text={`Approve ${getTemplateType()} from this template automatically`} />
                </View>
                <Switch
                  accessibilityLabel="auto-approval-switch"
                  onToggle={value => {
                    setIsAutoApproveConfirmationModalOpen(value);
                    setIsAutoApprove(value);
                    formRef.current?.setFieldValue("autoApprove", value);
                    formRef.current?.setFieldTouched("autoApprove", true);
                  }}
                  size="large"
                  value={isAutoApprove}
                />
              </View>
              <>
                {type === "Merit" && (
                  <View style={styles.cardContainer}>
                    <View style={{ alignItems: "center", flexDirection: "row" }}>
                      <Heading level="4">Auto-remove merits</Heading>
                      <HorizontalSpacer size={theme.spacing.s} />
                      <Tooltip text="You will need to update the data source to see the benefits of this feature." />
                    </View>
                    <Switch
                      accessibilityLabel="auto-remove-switch"
                      onToggle={value => {
                        setIsAutoRevokeConfirmationModalOpen(value);
                        setIsAutoRevoke(value);
                        formRef.current?.setFieldValue("autoRevoke", value);
                        formRef.current?.setFieldTouched("autoRevoke", true);
                      }}
                      size="large"
                      value={isAutoRevoke}
                    />
                  </View>
                )}
              </>
            </>
          </Formik>
        </View>
      </View>
      <Footer
        onCancel={unSaveConfirmation}
        onSave={() => {
          if (None(formRef.current)) {
            return;
          }

          if (formRef.current.isValid) {
            setIsConfirmationModalOpen(true);
          }
        }}
      />
      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="save"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            formRef.current?.handleSubmit();
            setIsConfirmationModalOpen(false);
          }}
          text={`Are you sure you want to save? Saving this change will impact any org that has extended this template from you. ${
            isAutoRevoke || isAutoApprove
              ? "You will need to update the data source in order to use this feature."
              : ""
          } `}
          title="Save configuration"
        />
      )}
      {isAutoRevokeConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="ok"
          onClose={() => {
            setIsAutoRevokeConfirmationModalOpen(false);
            formRef.current?.setFieldValue("autoRevoke", false);
            setIsAutoRevoke(false);
          }}
          onOk={() => {
            setIsAutoRevokeConfirmationModalOpen(false);
          }}
          text="Are you sure you want to turn this on? This change will impact any org that has extended this template from you."
          title="Update settings"
        />
      )}
      {isAutoApproveConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="ok"
          onClose={() => {
            formRef.current?.setFieldValue("autoApprove", false);
            setIsAutoApproveConfirmationModalOpen(false);
            setIsAutoApprove(false);
          }}
          onOk={() => {
            setIsAutoApproveConfirmationModalOpen(false);
          }}
          text="Are you sure you want to turn this on? This change will impact any org that has extended this template from you."
          title="Update settings"
        />
      )}
    </>
  );
};
