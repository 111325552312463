import * as Yup from "yup";

const fieldRuleSchema = Yup.object({
  id: Yup.string().uuid().required(),
  predicate: Yup.string().required("Predicate must be specified"),
  templateFieldId: Yup.string()
    .required("Template field must be selected")
    .uuid("Template field must be selected"),
  value: Yup.string(),
});

export const templateRuleSchema = Yup.object({
  fieldCombinationType: Yup.string().oneOf(["all", "any"] as const),
  fieldRules: Yup.array().of(fieldRuleSchema),
  fieldValuesType: Yup.string().oneOf(["any", "specific"] as const),
  id: Yup.string().uuid().required(),
  templateId: Yup.string()
    .required("Template must be selected")
    .uuid("Template must be selected")
    .nullable(),
  templateStatus: Yup.string().oneOf(["any", "active", "inactive"] as const),
});
